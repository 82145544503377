import {useState, useEffect, useRef} from 'react';
import {
  Button, GrayButton,
} from '@/components/common-components/v2/Button';
import {observer} from 'mobx-react';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';

import styled from 'styled-components';
import {Checkbox} from '@/components/common-components/v2';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGear, faXmark} from '@fortawesome/pro-solid-svg-icons';

import {Settings} from '../../settings';
import {Form, Radio, RadioChangeEvent, Spin, Tooltip} from 'antd';
import {faAngleDown, faAngleUp, faArrowRight, faCopy, faClockRotateLeft, faXmark as regularXmark} from '@fortawesome/pro-regular-svg-icons';
import {Headings} from './headings';
import {Questions} from './questions';
import {TermsToInclude} from './termsToInclude';
import {faAlignLeft, faH1, faImage, faLink, faQuestion, faBuilding} from '@fortawesome/pro-duotone-svg-icons';
import {LinksToInclude} from './linksToInclude';
import {opaqueColor} from '@/utils/colors';
import {Popover} from 'antd';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import styles from '../style.module.scss';
import {notification} from '@/utils/notification-v2';
import {CommonInput} from '@/components/common-components';
import {convertToRaw} from 'draft-js';
import {toJS} from 'mobx';
import {LinkSuggestionsPopup} from './linksToInclude/SuggestionsPopup/SuggestionsPopup';
import {Images} from './images';
import {copyToClipboardWithHtmlAndText} from '@/utils/clipboard';
import {TermsToExclude} from './termsToExclude';
import {CompanyInfo} from './companyInfo';
import {LargeBadge} from '@/components/common-components/components/badge';
interface Props {
  pageId: any;
  selectedContentLength?: string;
  selectedToneOfVoice?: string;
  selectedLanguage?: string;
  selectedPointOfView?: string;
  tableCheck?: boolean;
  listCheck?: boolean;
  teaseCheck?: boolean;
  boostDettectionCheck?: boolean;
  addGlobalBackgroundCheck?: boolean;
  literaryDevicesCheck?: boolean;
  keyTakawaysCheck?: boolean;
  disableSkinnyParagraphCheck?: boolean;
  generateHeroImageCheck?: boolean;
  shortFaq?: boolean;
  longFaq?: boolean;
  customToneOfVoice?: string;
  shouldOverwriteContent?: boolean;
  conclusionCheck?: boolean;
  generateMetadata?: boolean;
  imagesCheck?: boolean;
  imagesModel?: string;
  imagesAspectRatio?: string;
  replaceTextInEditor?: any;
  globalBackgroundDesc?: string;
  selectedFaq?: string;
  selectedAiModel?: string;
  setSelectedContentLength?: (value: string) => void;
  setSelectedToneOfVoice?: (value: string) => void;
  setSelectedPointOfView?: (value: string) => void;
  setSelectedLanguage?: (value: string) => void;
  setTableCheck?: (value: boolean) => void;
  setListCheck?: (value: boolean) => void;
  setImagesCheck?: (value: boolean) => void;
  setConclusionCheck?: (value: boolean) => void;
  setGenerateMetadata?: (value: boolean) => void;
  setTeaseCheck?: (value: boolean) => void;
  setBoostDettectionCheck?: (value: boolean) => void;
  setAddGlobalBackgroundCheck?: (value: boolean) => void;
  setLiteraryDevicesCheck?: (value: boolean) => void;
  setKeyTakeawaysCheck?: (value: boolean) => void;
  setDisableSkinnyParagraphCheck?: (value: boolean) => void;
  setGenerateHeroImageCheck?: (value: boolean) => void;
  setShowPageTitle?: (value: boolean) => void;
  setCustomToneOfVoice?: (value: string) => void;
  setShouldOverwriteContent?: (value: boolean) => void;
  setIsContentOverwritten?: (value: boolean) => void;
  setGlobalBackgroundDesc?: (value: string) => void;
  setImagesModel?: (value: string) => void;
  setSelectedFaq?: (value: string) => void;
  setElapsedTime?: (value: number) => void;
  setImagesAspectRatio?: (value: string) => void;
  setSelectedAiModel?: (value: string) => void;
}

export const Components = observer(({
  selectedContentLength,
  selectedToneOfVoice,
  selectedLanguage,
  selectedPointOfView,
  tableCheck,
  listCheck,
  teaseCheck,
  boostDettectionCheck,
  addGlobalBackgroundCheck,
  literaryDevicesCheck,
  keyTakawaysCheck,
  conclusionCheck,
  generateMetadata,
  imagesCheck,
  disableSkinnyParagraphCheck,
  generateHeroImageCheck,
  shouldOverwriteContent,
  globalBackgroundDesc,
  customToneOfVoice,
  imagesModel,
  selectedFaq,
  imagesAspectRatio,
  selectedAiModel,
  setSelectedContentLength,
  setSelectedToneOfVoice,
  setSelectedLanguage,
  setSelectedPointOfView,
  setTableCheck,
  setListCheck,
  setTeaseCheck,
  setBoostDettectionCheck,
  setConclusionCheck,
  setGenerateMetadata,
  setImagesCheck,
  setAddGlobalBackgroundCheck,
  setLiteraryDevicesCheck,
  setKeyTakeawaysCheck,
  setDisableSkinnyParagraphCheck,
  setGenerateHeroImageCheck,
  setCustomToneOfVoice,
  setShouldOverwriteContent,
  setGlobalBackgroundDesc,
  setIsContentOverwritten,
  setImagesModel,
  setSelectedFaq,
  setElapsedTime,
  setImagesAspectRatio,
  setSelectedAiModel,
}: Props) => {
  const {settings: {customer: {getCustomerQuota}}} = useStore('');

  const {contentOptimizer: {currentPage}} = useStore('');
  const {
    contentOptimizer: {
      pages: {getAllProjects, projects, updateContentProjectSettings, activeProjectId},
      currentPage: {
        currentEditorState,
        content,
        analytics,
        setArticlesInProgress,
      }, aiOutline: {
        setShowRemainingTerms,
        loadCompleteSeoArticle,
        setShowOutlinesInDrawer,
        loading,
        updateUserAiSettings,
        refetchOneClickDraftResultForArticleCreation,
        getUserAiSettings,
        setShouldOverwriteContentSuccessMessage,
        setAiArticleAlreadyReplaced,
        setCreatingAiArticleSuccessStatus,
        setTopUpBulkVisibility,
        setShowDesc,
        setCreatingAiArticleFailureStatus,
        userAiSettings,
      },
    },
    settings: {
      customer: {
        isAllowedAiPremiumContentGenerationQuotaLimitReached,
        profile: {
          isWhitelabel,
        },
      },
    },
  } = useStore('');

  const {aiInProgress} = content;
  const [form] = Form.useForm();


  const [usedTerms, setUsedTerms] = useState('');
  const [termsToInclude, setTermsToInclude] = useState<string[]>([]);
  const [excludeTerm, setExcludeTerm] = useState('');
  const [termsToExclude, setTermsToExclude] = useState<string[]>([]);
  const [showTermsToExcludeSection, setShowTermsToExcludeSection] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isContentIdeaError, setIsContentIdeaError] = useState(false);
  const [contentIdea, setContentIdea] = useState('');
  const [isEmptyArticle, setIsEmptyArticle] = useState(false);
  const [isAiQuestions, setIsAiQuestions] = useState(true);

  const [showHeadingsSection, setShowHeadingsSection] = useState(true);
  const [showTermsToIncludeSection, setShowTermsToIncludeSection] = useState(true);
  const [showQuestionsSection, setShowQuestionsSection] = useState(false);
  const [showLinksToIncludeSection, setShowLinksToIncludeSection] = useState(false);
  const [showSettingsSection, setShowSettingsSection] = useState(false);
  const [showImageSection, setShowImageSection] = useState(false);
  const [showCompanyInfoSection, setShowCompanyInfoSection] = useState(false);
  const [companyNameError, setCompanyNameError] = useState('');
  // const [totalH2Counter, setTotalH2Count] = useState(3);
  const [lastUsedToneOfVoice, setLastUsedToneOfVoice] = useState('');
  const [globalBackgroundError, setGlobalBackgroundError] = useState({
    message: 'Missing global background',
    error: false,
  });
  const contentIdeaRef = useRef(null);
  const [companyInfoErrors, setCompanyInfoErrors] = useState<{[key: number]: {isValid: boolean; errorMsg: string}}>({});
  const [showMainHeadingMissingError, setShowMainHeadingMissingError] = useState(false);
  const [showFocusTopicMissingError, setShowFocusTopicMissingError] = useState(false);
  const [contentType, setContentType] = useState(1);
  const [isHeadingCopied, setIsHeadingCopied] = useState(false);


  const [linksToIncludeState, setLinksToIncludeState] = useState<any>({
    isEmpty: true,
    items: [{
      value: '',
      anchorText: '',
      label: 'Link',
      placeholder: 'Link',
      fields: [

      ],
    },
    {
      value: '',
      anchorText: '',
      label: 'Link',
      placeholder: 'Link',
      fields: [
      ],
    },
    {
      value: '',
      anchorText: '',
      label: 'Link',
      placeholder: 'Link',
      fields: [
      ],
    },
    ],
  });
  const [bolFocusTerms, setBolFocusTerms] = useState(true);
  const [tableOfContents, setTableOfContents] = useState(true);
  const [actAsWho, setActAsWho] = useState<string>('');
  const [readingLevelValue, setReadingLevel] = useState<string>('8th & 9th grade');
  const [writingStyle, setWritingStyle] = useState<string>('');
  const [niche, setNiche] = useState<string>('');
  const [defaultAudience, setDefaultAudience] = useState<string>('');
  const [intent, setIntent] = useState('');
  const [searchIntent, setSearchIntent] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [targetUrl, setTargetUrl] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [gbpUrl, setGbpUrl] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [mediaContactName, setMediaContactName] = useState('');
  const [mediaContactEmail, setMediaContactEmail] = useState('');
  const [mediaContactPhone, setMediaContactPhone] = useState('');
  const [errors, setErrors] = useState({internal: [], external: []});

  useEffect(() => {
    async function loadProject() {
      await getAllProjects();
    }
    loadProject();
    onImportTermsFromArticleClick();
  }, []);


  useEffect(() => {
    setSelectedLanguage(userAiSettings?.defaultLanguage ? userAiSettings?.defaultLanguage : 'en');
  }, [userAiSettings?.defaultLanguage]);

  const project = projects.find((project: any) => project.name == content?.projectName) || projects[0];
  useEffect(()=>{
    if (showImageSection == false) {
      setGenerateHeroImageCheck(true);
      setImagesCheck(true);
    }
    if (project?.excludedFocusterms?.length > 0) {
      setTermsToExclude(project?.excludedFocusterms);
      setShowTermsToExcludeSection(true);
    }
    if (project && project?.defaultAiSettings) {
      let linksToInclude = [];
      setShowSettingsSection(true);
      setSelectedContentLength(project?.defaultAiSettings?.length || 'medium');
      setSelectedPointOfView(project?.defaultAiSettings?.pointOfView || 'third person');
      setSelectedToneOfVoice(project?.defaultAiSettings?.toneOfVoice || 'Professional');
      setTableCheck(!project?.defaultAiSettings?.useTable ? project?.defaultAiSettings?.useTable : true);
      setListCheck(!project?.defaultAiSettings?.useList ? project?.defaultAiSettings?.useList : true);
      setTeaseCheck(project?.defaultAiSettings?.useTease || false);
      setAddGlobalBackgroundCheck(project?.globalBackground !== '');
      setLiteraryDevicesCheck(
        project?.defaultAiSettings?.enableLiteraryDevices || false);
      setKeyTakeawaysCheck(!project?.defaultAiSettings?.enableKeyTakeaways ? project?.defaultAiSettings?.enableKeyTakeaways : true);
      setDisableSkinnyParagraphCheck(!project?.defaultAiSettings?.disableSkinnyParagraph ? project?.defaultAiSettings?.disableSkinnyParagraph : true);
      setConclusionCheck(!project?.defaultAiSettings?.disableConclusion ? project?.defaultAiSettings?.disableConclusion : true);
      setGenerateMetadata(!project?.defaultAiSettings?.generateMetadata ? project?.defaultAiSettings?.generateMetadata : true);
      setGlobalBackgroundDesc(project?.globalBackground || '');
      // setSelectedLanguage(project?.defaultAiSettings?.language);
      setSelectedFaq(project?.defaultAiSettings?.enableFaq?.toLowerCase() || 'short');
      setImagesAspectRatio(project?.defaultAiSettings?.imagesAspectRatio || '8:3');
      setBolFocusTerms([null, undefined].includes(project?.defaultAiSettings?.boldTermsToInclude) ? true : project?.defaultAiSettings?.boldTermsToInclude);
      setTableOfContents(!project?.defaultAiSettings?.tableOfContents ? project?.defaultAiSettings?.tableOfContents : true);
      setActAsWho(project?.defaultAiSettings?.actAsWho);
      setReadingLevel(project?.defaultAiSettings?.readingLevel || '8th & 9th grade');
      setWritingStyle(project?.defaultAiSettings?.writingStyle);
      setNiche(project?.defaultAiSettings?.niche);
      setDefaultAudience(project?.defaultAiSettings?.defaultAudience);
      setIntent(project?.defaultAiSettings?.intent);
      setSearchIntent(project?.defaultAiSettings?.searchIntent?.split(',')?.map(item => item.trim())?.filter(item => item !== ''));
      const showImagesSection = !!(
        project?.defaultAiSettings?.generateHeroImage ||
          project?.defaultAiSettings?.generateImages ||
          project?.defaultAiSettings?.imagesAspectRatio ||
          project?.defaultAiSettings?.imagesEngine
      );
      setShowImageSection(showImagesSection);
      if (showImagesSection) {
        setImagesCheck(!project?.defaultAiSettings?.generateImages ? project?.defaultAiSettings?.generateImages : true);

        setGenerateHeroImageCheck(!project?.defaultAiSettings?.generateHeroImage ? project?.defaultAiSettings?.generateHeroImage : true);
      }
      setImagesModel('Midjourney');

      if (project?.requiredInternalLinks?.length > 0) linksToInclude = loadGlobalLinks(linksToInclude, project.requiredInternalLinks);
      if (project?.requiredExternalLinks?.length > 0) linksToInclude = loadGlobalLinks(linksToInclude, project.requiredExternalLinks);
      if (project?.optionalInternalLinks?.length > 0) linksToInclude = loadGlobalLinks(linksToInclude, project.optionalInternalLinks);
      if (project?.optionalExternalLinks?.length > 0) linksToInclude = loadGlobalLinks(linksToInclude, project.optionalExternalLinks);
      if (linksToInclude.length > 0) {
        setLinksToIncludeState({
          isEmpty: true,
          items: linksToInclude});
        setShowLinksToIncludeSection(true);
      }
      setSelectedAiModel(project?.defaultAiSettings?.llm || 'gpt');
      const companyInfoSection = !!(
        project?.companyInfo?.companyName ||
          project?.companyInfo?.websiteUrl ||
          project?.companyInfo?.targetUrl ||
          project?.companyInfo?.gbpUrl ||
          project?.companyInfo?.businessAddress ||
          project?.companyInfo?.email ||
          project?.companyInfo?.phone ||
          project?.companyInfo?.mediaContactName ||
          project?.companyInfo?.mediaContactEmail ||
          project?.companyInfo?.mediaContactPhone
      );
      setShowCompanyInfoSection(companyInfoSection);
      if (companyInfoSection) {
        setCompanyName(project?.companyInfo?.companyName);
        setWebsiteUrl(project?.companyInfo?.websiteUrl);
        setTargetUrl(project?.companyInfo?.targetUrl);
        setGbpUrl(project?.companyInfo?.gbpUrl);
        setBusinessAddress(project?.companyInfo?.businessAddress);
        setEmail(project?.companyInfo?.email);
        setPhone(project?.companyInfo?.phone);
        setMediaContactName(project?.companyInfo?.mediaContactName);
        setMediaContactEmail(project?.companyInfo?.mediaContactEmail);
        setMediaContactPhone(project?.companyInfo?.mediaContactPhone);
      }
    }
  }, [project]);

  const loadGlobalLinks = (existingLinks: any, newLinks: any) => [...existingLinks, ...newLinks.map(link => generateLink(link))];

  const generateLink = linkValue => ({
    value: linkValue?.url || linkValue,
    anchorText: typeof(linkValue?.anchor) == 'string' ? linkValue?.anchor : '',
    label: 'Link',
    placeholder: 'Link',
    fields: [

    ],
  });
  // Adds single field into single Focus Topic section
  const addNewLinkField = (value?: {link: string; anchorText?: string}) => {
    const curState = {...linksToIncludeState};
    const items = curState['items']?.filter(item => item.value);

    items.push({
      label: 'Link',
      placeholder: 'Link',
      value: value.link,
      anchorText: '',
      fields: [],
    });

    const newState = {
      ...curState,
      items: items,
    };

    setLinksToIncludeState(newState);
  };

  const addBulkLinkFields = (total?: number) => {
    const curState = {...linksToIncludeState};
    const items = curState['items']?.filter(item => item.value);
    const linksList = toJS(currentPage)?.analytics?.commonCitations;
    for (let i = 0; i < total; i++) {
      if (linksList[i]?.url) {
        items.push({
          label: 'Link',
          placeholder: 'Link',
          value: linksList[i]?.url,
          anchorText: '',
          fields: [],
        });
      }
    }

    const newState = {
      ...curState,
      items: items,
    };
    setLinksToIncludeState(newState);
  };
  const addBulkInternalLinkFields = (total?: number) => {
    const curState = {...linksToIncludeState};
    const items = curState['items']?.filter(item => item.value);
    const linksList = toJS(content)?.internalLinkSuggestionsV2?.allLinks;
    for (let i = 0; i < total; i++) {
      if (linksList[i]?.url) {
        items.push({
          label: 'Link',
          placeholder: 'Link',
          value: linksList[i]?.url,
          anchorText: '',
          fields: [],
        });
      }
    }

    const newState = {
      ...curState,
      items: items,
    };
    setLinksToIncludeState(newState);
  };

  const [questionsState, setQuestionsState] = useState<any>({
    isEmpty: true,
    items: [{
      value: '',
      label: 'Question',
      placeholder: 'Question',
      fields: [

      ],
    },
    {
      value: '',
      label: 'Question',
      placeholder: 'Question',
      fields: [
      ],
    },
    {
      value: '',
      label: 'Question',
      placeholder: 'Question',
      fields: [
      ],
    },
    ],
  });

  const usedQuestions = [];
  questionsState?.items?.forEach(item => {
    if (item.value) {
      usedQuestions.push(item.value);
    }
  });

  const usedLinksForPayload = [];
  linksToIncludeState?.items?.forEach(item => {
    usedLinksForPayload.push({url: item.value, anchor: item.anchorText});
  });

  const [state, setState] = useState<any>({
    isEmpty: true,
    mainHeading: '',
    items: [{
      value: '',
      // label: 'Focus Topic (H2)',
      placeholder: 'H2',
      fields: [
        // {
        //   type: 'h3',
        //   label: 'H3',
        //   placeholder: 'Add a related question....',
        //   value: '',
        // },
      ],
    },
    ],
  });

  const clearAllSettingsFields = () => {
    setSelectedContentLength('medium');
    setSelectedLanguage('en');
    setSelectedPointOfView('third person');
    setSelectedToneOfVoice('Professional');
    setSelectedFaq('short');
    setTableCheck(true);
    setListCheck(true);
    setTeaseCheck(false);
    setBoostDettectionCheck(false);
    setAddGlobalBackgroundCheck(false);
    setLiteraryDevicesCheck(false);
    setKeyTakeawaysCheck(true);
    setDisableSkinnyParagraphCheck(true);
    setGenerateHeroImageCheck(true);
    setImagesCheck(true);
    setCustomToneOfVoice('');
    setConclusionCheck(true);
    setGenerateMetadata(true);
    setBolFocusTerms(true);
    setTableOfContents(true);
    setSelectedAiModel('gpt');
    setActAsWho('');
    setReadingLevel('8th & 9th grade');
    setWritingStyle('');
    setNiche('');
    setDefaultAudience('');
    setIntent('');
    setSearchIntent([]);
  };

  const resetFields = () => {
    getUserAiSettings();

    setState({
      isEmpty: true,
      mainHeading: '',
      items: [{
        value: '',
        // label: 'Focus Topic (H2)',
        placeholder: 'H2',
        fields: [],
      },
      ],
    });

    // setTotalH2Count(3);

    form?.setFieldsValue({
      title: '',
      termsToInclude: '',
    });
    setContentIdea('');
    setQuestionsState({
      isEmpty: true,
      items: [{
        value: '',
        label: 'Question',
        placeholder: 'Question',
        fields: [

        ],
      },
      {
        value: '',
        label: 'Question',
        placeholder: 'Question',
        fields: [
        ],
      },
      {
        value: '',
        label: 'Question',
        placeholder: 'Question',
        fields: [
        ],
      },
      ],
    });
    setLinksToIncludeState({
      isEmpty: true,
      items: [{
        value: '',
        anchorText: '',
        label: 'Link',
        placeholder: 'Link',
        fields: [

        ],
      },
      {
        value: '',
        anchorText: '',
        label: 'Link',
        placeholder: 'Link',
        fields: [
        ],
      },
      {
        value: '',
        anchorText: '',
        label: 'Link',
        placeholder: 'Link',
        fields: [
        ],
      },
      ],
    });
    setTermsToInclude([]);
    setUsedTerms('');
    setTermsToExclude([]);
    setExcludeTerm('');
    setImagesModel('Midjourney');
    setImagesAspectRatio('8:3');
    clearAllSettingsFields();
    setCompanyName(project?.companyInfo?.companyName);
    setWebsiteUrl(project?.companyInfo?.websiteUrl);
    setTargetUrl(project?.companyInfo?.targetUrl);
    setGbpUrl(project?.companyInfo?.gbpUrl);
    setBusinessAddress(project?.companyInfo?.businessAddress);
    setEmail(project?.companyInfo?.email);
    setPhone(project?.companyInfo?.phone);
    setMediaContactName(project?.companyInfo?.mediaContactName);
    setMediaContactEmail(project?.companyInfo?.mediaContactEmail);
    setMediaContactPhone(project?.companyInfo?.mediaContactPhone);
  };

  const importArticleSettings = () => {
    const findSettings =
      JSON.parse(localStorage.getItem('latest_settings')) || [];
    const settingData = findSettings?.find(
      (article: any) => article.uuid == content?.uuid);

    if (settingData?.contentType) {
      setContentType(settingData?.contentType);
    }

    if (settingData?.outline?.length) {
      setShowHeadingsSection(true);
      setState({
        isEmpty: true,
        mainHeading: settingData.title,
        items: settingData?.outline.map((item: any) => ({value: item?.h2, placeholder: 'H2', fields: item?.h3s.map(field=>({
          type: 'h3',
          label: 'H3',
          placeholder: 'Add a related question....',
          value: field.h3,
        }))})),
      });
    }

    setContentIdea(settingData.content_topic || '');
    if (settingData.terms_to_include?.length) {
      setShowTermsToIncludeSection(true);
      setTermsToInclude(settingData.terms_to_include || '');
    }
    if (settingData.questions_to_include?.length) {
      setShowQuestionsSection(true);
      setQuestionsState({
        isEmpty: true,
        items: settingData.questions_to_include.map((value: any) => {
          return {
            value: value,
            label: 'Question',
            placeholder: 'Question',
            fields: [],
          };
        }),
      });
    }
    if (settingData.links_to_include?.length) {
      setShowLinksToIncludeSection(true);
      setLinksToIncludeState({
        isEmpty: true,
        items: settingData.links_to_include.map((value: any) => {
          return {
            value: value.url,
            anchorText: value.anchor,
            label: 'Link',
            placeholder: 'Link',
            fields: [],
          };
        }),
      });
    }
    if (settingData.generate_hero_image || settingData.generate_images) {
      setShowImageSection(true);
      setImagesCheck(!settingData.generate_images ? settingData.generate_images : true);
      setGenerateHeroImageCheck(!settingData.generate_hero_image ? settingData.generate_hero_image : true);
      setImagesModel('Midjourney');
    }
    if (settingData.disable_conclusion || settingData?.length || settingData.enable_key_takeaways || settingData.use_tease || settingData.use_table || settingData.use_list ||
      // || settingData.enable_literary_devices
      settingData.tone_of_voice || settingData.point_of_view || settingData.use_global_background || settingData.disable_skinny_paragraph) {
      setShowSettingsSection(true);
      setSelectedContentLength(settingData?.length);
      setSelectedPointOfView(settingData.point_of_view || 'third person');
      setSelectedToneOfVoice(settingData.tone_of_voice || 'Professional');
      setTableCheck(!settingData.use_table ? settingData.use_table : true);
      setListCheck(!settingData.use_list ? settingData.use_list : true);
      setTeaseCheck(settingData.use_tease);
      setAddGlobalBackgroundCheck(settingData.use_global_background);
      // setLiteraryDevicesCheck(settingData.enable_literary_devices);
      setKeyTakeawaysCheck(!settingData.enable_key_takeaways ? settingData.enable_key_takeaways : true);
      setDisableSkinnyParagraphCheck(!settingData.disable_skinny_paragraph ? settingData.disable_skinny_paragraph : true);
      setConclusionCheck(!settingData.disable_conclusion ? settingData.disable_conclusion : true);
      setGenerateMetadata(!settingData.generate_metadata ? settingData.generate_metadata : true);
      setGlobalBackgroundDesc(settingData.global_background || '');
      setSelectedLanguage(settingData.language || 'en');
      setSelectedFaq(settingData.selectedFaq?.toLowerCase() || 'short');
      setSelectedAiModel(settingData.llm || 'gpt');
    }
  };

  const antIcon = <LoadingOutlined style={{fontSize: 14, marginLeft: '10px', color: '#fff', marginTop: '5px'}} spin />;

  // Series of validations to determen if submit should be disabled or not
  const disableButtonHandler = () => {
    // if main heading is missing
    if (loading || content.aiInProgress) return true;

    // if there are less than 1 topics with value
    // const topicValuesArr = state.items?.filter(item => item?.value);
    // if (topicValuesArr.length < 1) return true;

    return false;
  };

  const clearTermsToIncludeFromForm = () => {
    form.setFieldsValue({
      termsToInclude: '',
    });
  };
  const clearTermsToExcludeFromForm = () => {
    form.setFieldsValue({
      termsToExclude: '',
    });
  };

  const checkSetting = (article:any) => {
    return article.uuid == content?.uuid;
  };

  const latestSettings = JSON.parse(localStorage.getItem('latest_settings')) || [];
  const settingExists = latestSettings?.length ? latestSettings.some(checkSetting) : false;

  const ensureHttpPrefix = url => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  const generateAiOutlineHandler = async () => {
    if (contentType == 3 && showCompanyInfoSection) {
      if (Object.values(companyInfoErrors).some(b => !b.isValid)) {
        return;
      }
    }
    let validationError = false;
    const companyValidation = companyName?.trim();
    if (!companyValidation && contentType == 3) {
      if (showCompanyInfoSection) {
        validationError = true;
        setCompanyNameError('Company Name is required');
      } else {
        validationError = true;
        setCompanyNameError('Company is required');
      }
    } else {
      if (!showCompanyInfoSection && contentType == 3) {
        validationError = true;
        setCompanyNameError('Company is required');
      } else {
        validationError = false;
        setCompanyNameError('');
      }
    }
    if (contentType == 3 && !contentIdea) {
      validationError = true;
      setIsContentIdeaError(true);
      contentIdeaRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
    if (showSettingsSection) {
      if (!globalBackgroundDesc && addGlobalBackgroundCheck) {
        validationError = true;
        setGlobalBackgroundError({message: 'Missing global background', error: true});
      }
    }
    if (validationError) return;
    if (isWhitelabel && isAllowedAiPremiumContentGenerationQuotaLimitReached) {
      notification.error('Quota is exceeded', 'Top up your Premium AI Quota to Generate more articles.', false, 'Refresh page', 0, '', true, false, true, setTopUpBulkVisibility, setShowDesc);
    } else if (!isAllowedAiPremiumContentGenerationQuotaLimitReached) {
      if (disableButtonHandler()) return;
      if (showSettingsSection && addGlobalBackgroundCheck && !globalBackgroundDesc) {
        setGlobalBackgroundError({...globalBackgroundError, error: true});
      } else if (showSettingsSection && addGlobalBackgroundCheck && globalBackgroundDesc.length > 5000) {
        setGlobalBackgroundError({...globalBackgroundError, message: `Global background char must be less then 5000`, error: true});
      }
      setShouldOverwriteContentSuccessMessage(shouldOverwriteContent);
      setAiArticleAlreadyReplaced(false);
      setCreatingAiArticleSuccessStatus(false);
      setCreatingAiArticleFailureStatus(false);
      setElapsedTime(0);

      let showError = false;
      setGlobalBackgroundError({message: 'Missing global background', error: false});

      const curState = {...state};
      const items = curState['items'];

      const formatItems = [];
      items?.map(item => {
        if (item.value) {
          formatItems.push({
            ...item,
            fields: item?.fields?.filter(field => field.value !== ''),
          });
        } else {
          if (item?.fields?.filter(field => field.value !== '')?.length > 0) {
            formatItems.push({
              ...item,
              fields: item?.fields?.filter(field => field.value !== ''),
            });
            showError = true;
          }
        }
      });

      setState({
        ...state,
        items: formatItems,
      });

      if (showError) {
        setShowError(true);
        return;
      }

      const generateOutlines = formatItems => {
        const outlineArray = formatItems.map(item => ({
          h2: item.value,
          h3s: item.fields.map(field => ({h3: field.value})),
        }));
        return outlineArray;
      };
      if (!contentIdea && contentType==3) {
        setIsContentIdeaError(true);
        return;
      }

      const prepPayload = {
        // following must be sent, even when empty
        'title': state?.mainHeading as string ?? '',
        'content_topic': contentIdea ? contentIdea : toJS(currentPage?.feTargetKeywords)?.length ? toJS(currentPage?.feTargetKeywords)?.map( value => value?.keyword).join(', ') : '',
        'outline': showHeadingsSection ? (generateOutlines(formatItems) ?? []) : [],
        'uuid': content.uuid,
        // following is optional

        ...(showQuestionsSection && {'questions_to_include': isAiQuestions? []:usedQuestions}),
        ...(showLinksToIncludeSection && {'links_to_include': usedLinksForPayload}),
        ...(showTermsToIncludeSection && {'terms_to_include': termsToInclude?.length && usedTerms ? [...termsToInclude, ...usedTerms.split(',')] : termsToInclude?.length ? termsToInclude : usedTerms.split(',')}),
        ...(shouldOverwriteContent && {'should_set_page_title': true}),
        ...(contentType === 1 && selectedFaq && {'enable_faq': selectedFaq}),
        ...((selectedFaq && selectedFaq.toLowerCase() === 'short') && {'use_faq_short_answers': true}),
        ...((selectedFaq && selectedFaq.toLowerCase() === 'long') && {'use_faq_long_answers': true}),

        ...(showImageSection && imagesCheck && {'generate_images': imagesCheck}),
        ...(showImageSection && generateHeroImageCheck && {'generate_hero_image': generateHeroImageCheck}),
        ...(showImageSection && {'images_engine': 'midjourney'}),
        ...(showImageSection && {'images_aspect_ratio': imagesAspectRatio}),
        ...(selectedLanguage && {'language': selectedLanguage}),
        'content_type': contentType === 1 ? 'process_full_seo_article_writer' : contentType === 2 ? 'landing_page_copy' : 'press_release',
        // fields from settings dropdown
        ...(showSettingsSection && {
          ...(tableCheck && {'use_table': tableCheck}),
          ...(listCheck && {'use_list': listCheck}),
          // ...(literaryDevicesCheck && {'enable_literary_devices': literaryDevicesCheck}),
          'tone_of_voice': selectedToneOfVoice == 'custom' ? customToneOfVoice ? customToneOfVoice : lastUsedToneOfVoice : selectedToneOfVoice,
          ...(contentType === 1 && selectedPointOfView && {'point_of_view': selectedPointOfView}),
          ...(addGlobalBackgroundCheck && {'use_global_background': addGlobalBackgroundCheck}),
          ...(addGlobalBackgroundCheck && {'global_background': globalBackgroundDesc}),
          ...(contentType === 1 && disableSkinnyParagraphCheck && {'disable_skinny_paragraph': disableSkinnyParagraphCheck}),
          ...(contentType === 1 && teaseCheck && {'use_tease': teaseCheck}),
          ...(contentType === 1 && keyTakawaysCheck && {'enable_key_takeaways': keyTakawaysCheck}),
          ...(selectedContentLength && {'length': selectedContentLength.toLowerCase()}),
          ...(contentType === 1 && conclusionCheck && {'disable_conclusion': conclusionCheck}),
          ...(generateMetadata && {'generate_metadata': generateMetadata}),
        }),
        'should_populate_page_html': shouldOverwriteContent,
        'llm': selectedAiModel,
        'bold_terms_to_include': bolFocusTerms,
        'table_of_contents': tableOfContents,
        'act_as_who': actAsWho,
        'reading_level': readingLevelValue,
        'writing_style': writingStyle,
        'niche': niche,
        'default_audience': defaultAudience,
        'intent': intent,
        'search_intent': searchIntent?.join(', '),
        ...(contentType==3 && showCompanyInfoSection && {
          'company_name': companyName,
          'website_url': websiteUrl && ensureHttpPrefix(websiteUrl),
          'target_url': targetUrl && ensureHttpPrefix(targetUrl),
          'business_address': businessAddress,
          'gbp_url': gbpUrl && ensureHttpPrefix(gbpUrl),
          'email': email,
          'phone': phone,
          'media_contact_name': mediaContactName,
          'media_contact_email': mediaContactEmail,
          'media_contact_phone': mediaContactPhone,
        }),
      };

      for (const key in prepPayload) {
        if (key !== 'global_background') {
          if ((Array.isArray(prepPayload[key])) && !prepPayload[key].length) {
            delete prepPayload[key];
          } else if (prepPayload[key] === null || prepPayload[key] === '') {
            delete prepPayload[key];
          }
        }
      }

      if (contentType == 3) {
        delete prepPayload['title'];
        delete prepPayload['outline'];
      }

      const otherDetails = {
        ...prepPayload,
        language: selectedLanguage,
        selectedFaq: selectedFaq,
        contentType: contentType,
      };
      if (settingExists) {
        latestSettings?.forEach((article: any, index: number) => {
          if (article.uuid === content.uuid) {
            latestSettings[index] = otherDetails;
          }
        });
      } else {
        latestSettings?.push(otherDetails);
      }
      localStorage.setItem('latest_settings', JSON.stringify(latestSettings));

      try {
        setIsContentOverwritten(false);
        if (showSettingsSection) {
          await updateUserAiSettings({
            language: selectedLanguage,
            toneOfVoice: selectedToneOfVoice == 'custom' ? customToneOfVoice ? customToneOfVoice : lastUsedToneOfVoice : selectedToneOfVoice,
            pointOfView: selectedPointOfView,
          });
        }
        if (showTermsToExcludeSection) {
          const foundProject = toJS(projects).find((project: any) => project.name == content?.projectName) || projects[0];
          const payload = {excluded_focusterms: termsToExclude?.length && excludeTerm ? [...termsToExclude, ...excludeTerm.split(',')] : termsToExclude?.length ? termsToExclude : excludeTerm.split(',')};
          await updateContentProjectSettings({payload, projectId: activeProjectId || foundProject?.id});
        }
        const response = await loadCompleteSeoArticle(prepPayload);
        if (!response?.id) return;


        const now = new Date().getTime();
        currentPage.loadPage(content.uuid as string, false);
        await refetchOneClickDraftResultForArticleCreation(content.uuid, now);

        if (shouldOverwriteContent && response?.id) {
          setIsContentOverwritten(true);
        }

        setShowOutlinesInDrawer(true);
      } catch (e) {
        setIsContentOverwritten(false);
      } finally {
        getCustomerQuota();
      }
    } else {
      setShowDesc(true);
      setArticlesInProgress(1);
      setTopUpBulkVisibility(true);
    }
  };

  const getAllHeadings = (): string[] => {
    const curState = {...state};
    const items = curState['items'];

    const formatItems = [];
    items?.map(item => {
      if (item.value) {
        formatItems.push({
          ...item,
          fields: item?.fields?.filter(field => field.value !== ''),
        });
      }
    });

    return formatItems?.map(item => item.value);
  };


  const settingsComponent = <Settings
    isDropdown={false}
    passedTitle={state?.mainHeading}
    passedHeadings={getAllHeadings()}
    title='Awaken the True AI Potential with Custom Settings'
    tableCheck={tableCheck}
    setTableCheck={setTableCheck}
    listCheck={listCheck}
    setListCheck={setListCheck}
    teaseCheck={teaseCheck}
    setTeaseCheck={setTeaseCheck}
    generateMetadata={generateMetadata}
    conclusionCheck={conclusionCheck}
    imagesCheck={imagesCheck}
    setConclusionCheck={setConclusionCheck}
    setGenerateMetadata={setGenerateMetadata}
    setImagesCheck={setImagesCheck}
    boostDettectionCheck={boostDettectionCheck}
    setBoostDettectionCheck={setBoostDettectionCheck}
    addGlobalBackgroundCheck={addGlobalBackgroundCheck}
    setAddGlobalBackgroundCheck={setAddGlobalBackgroundCheck}
    literaryDevicesCheck={literaryDevicesCheck}
    setLiteraryDevicesCheck={setLiteraryDevicesCheck}
    keyTakawaysCheck={keyTakawaysCheck}
    setKeyTakeawaysCheck={setKeyTakeawaysCheck}
    disableSkinnyParagraphCheck={disableSkinnyParagraphCheck}
    setDisableSkinnyParagraphCheck={setDisableSkinnyParagraphCheck}
    generateHeroImageCheck={generateHeroImageCheck}
    setGenerateHeroImageCheck={setGenerateHeroImageCheck}
    selectedToneOfVoice={selectedToneOfVoice}
    setSelectedToneOfVoice={setSelectedToneOfVoice}
    selectedPointOfView={selectedPointOfView}
    setSelectedPointOfView={setSelectedPointOfView}
    selectedLanguage={selectedLanguage}
    setSelectedLanguage={setSelectedLanguage}
    selectedContentLength={selectedContentLength}
    setSelectedContentLength={setSelectedContentLength}
    selectedFaq={selectedFaq}
    setSelectedFaq={setSelectedFaq}
    selectedAiModel={selectedAiModel}
    setSelectedAiModel={setSelectedAiModel}
    settingsArray={contentType ===1 ?[
      'tone-of-voice',
      'point-of-view',
      'use_table',
      'use_list',
      'use_tease',
      // 'boost-dettection-chance',
      'use_global_background',
      // 'enable_literary_devices',
      'key-takeaways',
      'disable_skinny_paragraph',
      'provide-details',
      'h2-section-length',
      'use_faq',
      'number-of-pieces',
      'language',
      'disable_conclusion',
      'generate_metadata',
      'ai-model',
      'bold_focus_terms',
      'table_of_contents',
      'act_as_who',
      'reading_level',
      'writing_style',
      'niche',
      'default_audience',
      'intent',
      'search_intent',
    ] : contentType ===2 ? [
      'tone-of-voice',
      'use_table',
      'use_list',
      // 'boost-dettection-chance',
      'use_global_background',
      // 'enable_literary_devices',
      'provide-details',
      'h2-section-length',
      'use_faq',
      'number-of-pieces',
      'language',
      'generate_metadata',
      'bold_focus_terms',
    ] : [
      'tone-of-voice',
      'use_global_background',
      'provide-details',
      'h2-section-length',
      'use_faq',
      'number-of-pieces',
      'language',
      'generate_metadata',
      'ai-model',
      'bold_focus_terms',
      'table_of_contents',
    ]}
    customToneOfVoice={customToneOfVoice}
    setCustomToneOfVoice={setCustomToneOfVoice}
    setLastUsedToneOfVoice={setLastUsedToneOfVoice}
    globalBackgroundDesc={globalBackgroundDesc}
    setGlobalBackgroundDesc={setGlobalBackgroundDesc}
    globalBackgroundError={globalBackgroundError}
    setGlobalBackgroundError={setGlobalBackgroundError}
    setBolFocusTerms={setBolFocusTerms}
    bolFocusTerms={bolFocusTerms}
    setTableOfContents={setTableOfContents}
    tableOfContents={tableOfContents}
    contentType={contentType}
    setActAsWho={setActAsWho}
    actAsWho={actAsWho}
    setReadingLevel={setReadingLevel}
    readingLevelValue={readingLevelValue}
    setWritingStyle={setWritingStyle}
    writingStyle={writingStyle}
    setNiche={setNiche}
    niche={niche}
    setDefaultAudience={setDefaultAudience}
    defaultAudience={defaultAudience}
    setIntent={setIntent}
    intent={intent}
    setSearchIntent={setSearchIntent}
    searchIntent={searchIntent}
  />;

  const autoPopulateFieldsHandler = () => {
    const contentState = currentEditorState.getCurrentContent();
    let missingDataError = false;
    setIsEmptyArticle(false);

    // checking if editor text is missing h1 tags
    if (!convertToRaw(contentState)?.blocks?.filter(block => block.text && (block.type === 'header-one'|| block.type === 'header-two'))?.length) {
      setShowMainHeadingMissingError(true);
      setIsEmptyArticle(true);
      missingDataError=true;
    } else {
      setShowMainHeadingMissingError(false);
    }

    // checking if editor text is missing h2 tags
    if (!convertToRaw(contentState)?.blocks?.filter(block => block.text && (block.type === 'header-two' || block.type === 'header-one'))?.length) {
      setShowFocusTopicMissingError(true);
      setIsEmptyArticle(true);
      missingDataError=true;
    } else {
      setShowFocusTopicMissingError(false);
    }

    if (missingDataError) return;

    const acceptableBlockTypes = ['header-one', 'header-two', 'header-three'];

    const fieldsMap = [];
    let mainHeading = '';
    let h3Array = [];

    // setting index of first h1 block, from where we will count all h2 until next h1
    let indexOfFirstHeading = convertToRaw(contentState)?.blocks?.findIndex(block => block.type === 'header-one' && block.text);
    if (indexOfFirstHeading === -1) indexOfFirstHeading = 0;

    // setting index of next h1 block, where we stop counting h2s
    let indexOfSecondHeading = convertToRaw(contentState)?.blocks?.length;
    for (let i = indexOfSecondHeading + 1; i < convertToRaw(contentState)?.blocks?.length; i++) {
      if (convertToRaw(contentState)?.blocks[i]?.type === 'header-one' && convertToRaw(contentState)?.blocks[i]?.text) {
        indexOfSecondHeading = i;
        break;
      }
    }

    convertToRaw(contentState)?.blocks?.forEach((block, idx) => {
      if (!acceptableBlockTypes.includes(block.type)) return;

      if (idx < indexOfFirstHeading || idx >= indexOfSecondHeading) return;

      if (block.type === 'header-one') {
        mainHeading = block.text;
      } else if (block.type === 'header-two') {
        if (fieldsMap.length > 0) {
          fieldsMap[fieldsMap.length - 1].fields = h3Array;
        }
        fieldsMap.push({
          value: block.text,
          label: 'H2',
          placeholder: 'H2',
          fields: [],
        });
        h3Array = [];
      } else {
        h3Array.push({
          type: 'h3',
          label: 'H3',
          placeholder: 'Add a related question....',
          value: block.text,
        });
      }
    });

    if (fieldsMap.length > 0) {
      fieldsMap[fieldsMap.length - 1].fields = h3Array;
    }

    setShowHeadingsSection(true);
    setShowTermsToIncludeSection(true);

    // cleaning up missing h1 and h2 errors
    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }

    const termsFromArticle = currentPage?.analytics?.focusTerms?.map(term => term.name);

    const uniqueTerms = new Set([...(termsFromArticle ?? []), ...(termsToInclude ?? [])]);
    const uniqueTermsArray = Array.from(uniqueTerms);
    setTermsToInclude(uniqueTermsArray);

    setShowRemainingTerms(false);

    setState({
      mainHeading: mainHeading,
      items: fieldsMap,
    });
  };

  const onImportTermsFromArticleClick = () => {
    const termsFromArticle = analytics?.focusTerms?.map(term => term.name);

    const uniqueTerms = new Set([...(termsFromArticle ?? []), ...(termsToInclude ?? [])]);
    const uniqueTermsArray = Array.from(uniqueTerms);
    setTermsToInclude(uniqueTermsArray);

    setShowRemainingTerms(false);
  };

  const copyHeadings = returnValue => {
    let allHeadingsHtml = `<h1>${state?.mainHeading}</h1>`;
    let allHeadingsText = state?.mainHeading;
    state?.items?.forEach(item => {
      if (item?.value) {
        allHeadingsHtml += `<h2>${item.value}</h2>`;
        allHeadingsText += ` ${item.value}`;
      }
      if (item?.fields) {
        item?.fields?.forEach(field => {
          if (field?.value) {
            allHeadingsHtml += `<${field.type}>${field.value}</${field.type}>`;
            allHeadingsText += ` ${field.value}`;
          }
        });
      }
    });
    if (returnValue) {
      return Boolean(allHeadingsText);
    } else {
      copyToClipboardWithHtmlAndText(allHeadingsHtml, allHeadingsText);
      setIsHeadingCopied(true);
    }
  };

  const sections = [
    {
      title: 'Add headings',
      icon: <FontAwesomeIcon icon={faH1} color={'#7F4EAD'} />,
      extraContent: <span style={{display: 'flex'}}>{copyHeadings(true) ?
        <Tooltip title='Copy Headings Outline to Clipboard'>
          <span style={{color: '#2D6CCA', cursor: 'pointer'}} onClick={() => copyHeadings(false)}>{
            isHeadingCopied ? '✅ Copied' : <><FontAwesomeIcon icon={faCopy} style={{marginRight: 4}} />Copy</>
          }</span>
        </Tooltip> : <></>}</span>,
      component: <Headings
        setShowFocusTopicMissingError={setShowFocusTopicMissingError}
        setShowMainHeadingMissingError={setShowMainHeadingMissingError}
        showFocusTopicMissingError={showFocusTopicMissingError}
        showMainHeadingMissingError={showMainHeadingMissingError}
        setState={data => {
          isHeadingCopied && setIsHeadingCopied(false);
          setState(data);
        }}
        state={state}
        contentType={contentType}
        showError={showError}
        contentIdea={contentIdea}
        setContentIdea={setContentIdea}/>,
      onShowStateChange: (state: boolean) => setShowHeadingsSection(state),
      expanded: showHeadingsSection,
    },
    {
      title: 'Terms to Include',
      icon: <FontAwesomeIcon icon={faAlignLeft} color={'#7F4EAD'} />,
      component: <TermsToInclude
        setTermsToInclude={setTermsToInclude}
        termsToInclude={termsToInclude}
        clearTermsToIncludeFromForm={clearTermsToIncludeFromForm}
        usedTerms={usedTerms}
        contentType={contentType}
        setUsedTerms={setUsedTerms} />,
      extraContent: <p style={{marginBottom: 0, color: '#13408B', marginLeft: 'auto', marginRight: 10, cursor: 'pointer', fontSize: 12}} onClick={() => onImportTermsFromArticleClick()}>Import from Article </p>,
      onShowStateChange: (state: boolean) => {
        setShowTermsToIncludeSection(state);
        if (state) {
          onImportTermsFromArticleClick();
        }
      },
      expanded: showTermsToIncludeSection,
    },
    {
      title: 'Terms to Exclude',
      icon: <FontAwesomeIcon icon={regularXmark} color={'##7F4EAD'} />,
      component: <TermsToExclude
        inputPlaceholder='The AI Writer will aim to write content without the specified terms.
Enter term(s) and phrase(s), separated by commas, and hit enter.'
        inputBackgroundColor='#fff'
        wrapperBackgroundColor='rgb(255, 255, 255)'
        containerBorder='1px solid rgb(232, 232, 232)'
        setExcludeTermsArray={setTermsToExclude}
        excludeTermsArray={termsToExclude}
        clearTermsToExclude={clearTermsToExcludeFromForm}
        excludeTerm={excludeTerm}
        setExcludeTerm={setExcludeTerm}
        inputBorder='none'/>,
      extraContent: <></>,
      onShowStateChange: (state: boolean) => {
        setShowTermsToExcludeSection(state);
      },
      expanded: showTermsToExcludeSection,
    },
    {
      title: 'Questions to Include (FAQ section)',
      icon: <FontAwesomeIcon icon={faQuestion} color={'#7F4EAD'} />,
      component: <Questions setState={setQuestionsState} state={questionsState} usedQuestions={usedQuestions} selectedFaq={selectedFaq} setSelectedFaq={setSelectedFaq} isAiQuestions={isAiQuestions} setIsAiQuestions={setIsAiQuestions} />,
      onShowStateChange: (state: boolean) => setShowQuestionsSection(state),
      expanded: showQuestionsSection,
    },
    {
      title: 'Links to Include',
      icon: <FontAwesomeIcon icon={faLink} color={'#7F4EAD'} />,
      component: <LinksToInclude errors={errors} setErrors={setErrors} setState={setLinksToIncludeState} state={linksToIncludeState}/>,
      extraContent: <Popover
        autoAdjustOverflow={true}
        placement='topLeft'
        content={<LinkSuggestionsPopup addItem={addNewLinkField} bulkAddExternalLink={addBulkLinkFields} bulkAddInternalLink={addBulkInternalLinkFields}/>}
        trigger='click'
        overlayClassName={styles.suggestionsPopover}>
        <GetSuggestionsBtn style={{marginLeft: 'auto', textDecoration: 'underline'}}>Get Suggestions <FontAwesomeIcon icon={faCaretDown}/></GetSuggestionsBtn>
      </Popover>,
      onShowStateChange: (state: boolean) => setShowLinksToIncludeSection(state),
      expanded: showLinksToIncludeSection,
    },
    {
      title: 'Add images',
      icon: <FontAwesomeIcon icon={faImage} color={'#7F4EAD'} />,
      component: <Images
        imagesModel={imagesModel}
        setImagesModel={setImagesModel}
        imagesCheck={imagesCheck}
        setImagesCheck={setImagesCheck}
        generateHeroImageCheck={generateHeroImageCheck}
        setGenerateHeroImageCheck={setGenerateHeroImageCheck}
        setImagesAspectRatio={setImagesAspectRatio}
        imagesAspectRatio={imagesAspectRatio}
      />,
      extraContent: null,
      onShowStateChange: (state: boolean) => {
        setShowImageSection(state);
        if (state) {
          setImagesCheck(true);
          setGenerateHeroImageCheck(true);
        }
      },
      expanded: showImageSection,
    },
    {
      title: 'Company Info',
      icon: <FontAwesomeIcon icon={faBuilding} color={'#7F4EAD'} />,
      component: <CompanyInfo
        setCompanyName={setCompanyName}
        companyName={companyName}
        setWebsiteUrl={setWebsiteUrl}
        websiteUrl={websiteUrl}
        setTargetUrl={setTargetUrl}
        targetUrl={targetUrl}
        setBusinessAddress={setBusinessAddress}
        businessAddress={businessAddress}
        setGbpUrl={setGbpUrl}
        gbpUrl={gbpUrl}
        setEmail={setEmail}
        email={email}
        setPhone={setPhone}
        phone={phone}
        setMediaContactName={setMediaContactName}
        mediaContactName={mediaContactName}
        setMediaContactEmail={setMediaContactEmail}
        mediaContactEmail={mediaContactEmail}
        setMediaContactPhone={setMediaContactPhone}
        mediaContactPhone={mediaContactPhone}
        defaultCompanyName={project?.companyInfo?.companyName}
        companyNameErrors={companyNameError}
        setCompanyNameErrors={setCompanyNameError}
        errors={companyInfoErrors}
        setErrors={setCompanyInfoErrors}
      />,
      extraContent: null,
      onShowStateChange: (state: boolean) => setShowCompanyInfoSection(state),
      expanded: showCompanyInfoSection,
    },
    {
      title: 'Customized AI Writer Settings',
      icon: <FontAwesomeIcon icon={faGear} color={'#7F4EAD'} />,
      component: settingsComponent,
      extraContent: <FontAwesomeIcon style={{cursor: 'pointer'}} icon={showSettingsSection ? faAngleUp : faAngleDown} color={'#7F4EAD'} onClick={() => setShowSettingsSection(!showSettingsSection)}/>,
      onShowStateChange: (state: boolean) => setShowSettingsSection(state),
      expanded: showSettingsSection,
      persistantExtraContent: true,
      preview: true,
    },
  ];

  const onChange = (e: RadioChangeEvent) => {
    setContentType(e.target.value);
  };

  return (
    <div style={{display: 'flex'}}>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
        Content type:
          <Radio.Group onChange={onChange} value={contentType}>
            <RadioStyled value={1}>Blog post</RadioStyled>
            <RadioStyled value={2}>Landing page</RadioStyled>
            <div style={{display: 'flex'}}><RadioStyled value={3}>Press Release</RadioStyled><LargeBadge style={{marginLeft: -10}} beta/></div>
          </Radio.Group>
        </div>
        <div style={{marginBottom: 20}}>
          <CommonInputStyled
            boxShadow={true}
            border={true}
            value={contentIdea}
            onChange={e => {
              if (e.target.value) setIsContentIdeaError(false);
              setContentIdea(e.target.value);
            }}
            type='text'
            variant='white'
            placeholder='Enter a topic (e.g what is digital marketing)' />
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{fontSize: 11, color: '#4e5156'}}>Quick populate data:</span>
            <div
              onClick={() => autoPopulateFieldsHandler()}
              style={{fontSize: 11, color: '#2D6CCA', cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: 5, marginRight: 5}}>
              <FontAwesomeIcon icon={faCopy} style={{marginRight: 5}}/>
              Import from Article
            </div>
            {settingExists ?
              <div onClick={() => importArticleSettings()} style={{fontSize: 11, color: '#2D6CCA', cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: 5, marginRight: 5}}>
                <span style={{marginRight: 10}}>or </span><FontAwesomeIcon icon={faClockRotateLeft} style={{marginRight: 5}}/>Use latest settings
              </div> : '' }
            {/* or
            <div style={{fontSize: 12, color: '#2D6CCA', cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: 5, marginRight: 5}}>
              <FontAwesomeIcon icon={faCheck} style={{marginRight: 5}}/>
              Use best settings
            </div> */}
          </div>
          <div ref={contentIdeaRef}>
            {isEmptyArticle && <p style={{fontSize: 12, color: '#ff5959', marginTop: 5}}>Article is empty</p>}
            {isContentIdeaError && <p style={{fontSize: 12, color: '#ff5959', marginTop: 5}}>Missing content idea</p>}
          </div>
        </div>
        {sections?.map((section, idx) => {
          let showSection = true;
          if (section?.title == 'Company Info' && contentType!==3 || (section?.title == 'Add headings' && contentType == 3)) {
            showSection = false;
          }
          return showSection && <SingleSection key={idx}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <SectionCheckbox
                onChange={e => {
                  section.onShowStateChange(e.target.checked);
                  setCompanyNameError('');
                }} checked={section.expanded}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <SectionIconContainer>
                    {section.icon}
                  </SectionIconContainer>
                  <SectionLabel>
                    {section.title}
                  </SectionLabel>
                </div>
              </SectionCheckbox>
              {(section.expanded || section.persistantExtraContent) && <ExtraContentSection>{section.extraContent}</ExtraContentSection>}
            </div>
            {!section.expanded && section?.title === 'Company Info' && companyNameError && (
              <ErrorMessageWrapper><ErrorText>{companyNameError}</ErrorText></ErrorMessageWrapper>
            )}
            <SectionComponent preview={section.preview} visible={(section.expanded)}>
              {section.component}
            </SectionComponent>
          </SingleSection>;
        })}

        <CheckboxStyled checked={shouldOverwriteContent} onChange={e => setShouldOverwriteContent(e.target.checked)}>Replace current article with AI Generated Content</CheckboxStyled>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 25, marginBottom: 25}}>
          {!disableButtonHandler() && !aiInProgress ?
            <Button
              onClick={generateAiOutlineHandler}
              style={{width: '200px', display: 'flex', justifyContent: 'center'}}>
              {`Generate AI Content`}
              <FontAwesomeIcon style={{marginLeft: 10}} icon={faArrowRight} />
            </Button> :
            <Tooltip placement='bottom' title={disableButtonHandler() ? 'Please wait for the current article to finish generating' : null}>
              <Button
                onClick={() => {}}
                style={{width: '200px', display: 'flex', justifyContent: 'center', opacity: .5, cursor: 'not-allowed'}}>
                {`Generate AI Content`}
                <Spin indicator={antIcon} />
              </Button>
            </Tooltip>
          }
          <GrayButton color='transparent' variant='noBorder' style={{marginLeft: 0}} onClick={() => resetFields()}>
            <FontAwesomeIcon style={{marginRight: 10}} icon={faXmark} />
          Clear inputs
          </GrayButton>
        </div>
      </div>
    </div>
  );
});

const CommonInputStyled = styled(CommonInput)<{border?: boolean; boxShadow?: boolean}>`
  color: #121212 !important;
  border-radius: 6px !important;
  ${p => p.boxShadow && 'box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02)'};
  ${p => !p.border && 'border: 1px solid transparent !important'};
  padding-right: 8px !important;
  margin-top: 10px !important;
  margin-bottom: 8px !important;
  
  &:focus {
    box-shadow: none !important;
  }
 
`;

const CheckboxStyled = styled(Checkbox)`
  margin-left: 0px !important;
  margin-top: 10px !important;
  font-size: 16px;

  .ant-checkbox-inner {
    border-radius: 4px;
  }
`;
const SectionCheckbox = styled(Checkbox)`
  margin-right: 10px !important;
  font-size: 12px;

  .ant-checkbox-inner {
    border-radius: 4px;
  }
`;
const SectionIconContainer = styled.div`
    width: 26px;
    height: 26px;
    background-color: ${opaqueColor('#7F4EAD', 10)};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;
const ExtraContentSection = styled.div`
  margin-left: auto;
`;
const SectionComponent = styled.div<{visible?: boolean; preview?: boolean}>`
  margin-left: 25px;
  margin-top: 10px;
  overflow: hidden;
  height: ${p => p.visible ? '100%' : p.preview ? '60px' : '0px'};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100%;
    display: ${p => !p.visible && p.preview ? 'block' : 'none'};
    background: ${p => !p.visible && p.preview ? 'linear-gradient(179deg, rgba(255, 255, 255, 0.00) -4.85%, #FFF 108.18%)' : 'transparent'};
  }
 
`;
const SectionLabel = styled.div`
  color: #121212;
  font-weight: 600;
`;

const SingleSection = styled.div`
  margin-bottom: 20px;
  overflow: hidden;
`;
const GetSuggestionsBtn = styled.div`
  color:  #2D6CCA;
  cursor: pointer;
  margin-right: 20px;
  font-size: 12px;
`;

const RadioStyled = styled(Radio)`
    .ant-radio-checked .ant-radio-inner {
      background-color: #7f4ead !important;
      border-color: #7f4ead !important;

      &::after {
        background-color: #fff !important;
      }
    }
`;
const ErrorMessageWrapper = styled.span`
  color: rgb(255, 89, 89);
  font-size: 10px;
  border: '2px solid black' !important;
  line-height: 1px;
`;
const ErrorText = styled.p`
  font-size: 11px;
  color: rgb(255, 89, 89);
  padding-left: 60px;
  line-height: -1px;
`;
