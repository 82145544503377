import React, {useEffect, useState} from 'react';
import GeneralSection from './generalSection';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Form, Row} from 'antd';
import {
  navigation,
  writingLeftOptions,
  writingRightOptions,
} from './commonFolderSettingsUtils';
import WritingSection from './writingSection';
import TermsToExclude from './termsToExclude';
import ImagesSection from './imagesSection';
import LinksToIncludeSection from './linksToIncludeSection';
import WpSection from './wpSection';
import {observer} from 'mobx-react';
import {snakeToCamel} from '@/utils/string';
import Footer from './footer';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {HeadingContainer, ModalHeading, StyledCol, SectionDivider, Option, FreezeBanner} from './styles';
import CompanyInfoSection from './companyInfoSection';
import {useStore} from '@/store/root-store';
import {LargeBadge} from '@/components/common-components/components/badge';
import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';
import {useRouter} from 'next/router';

const ModalContent = ({
  form,
  isEdit,
  termsToExclude,
  setTermsToExclude,
  wpId,
  setWpId,
  selectedToneOfVoice,
  setSelectedToneOfVoice,
  currentProjectData,
  terms,
  setTerms,
  linksToIncludeState,
  setLinksToIncludeState,
  externalLinksToIncludeState,
  setExternalLinksToIncludeState,
  onClose,
  isInTab = false,
  formKey = -1,
  setVisible,
  setIsEdit,
}) => {
  const {settings: {customer: {profile: {isLinkgraph}}}, ottoV2Store: {getOttoV2Project}} = useStore('');
  const {contentOptimizer: {aiOutline: {updateUserAiSettings}, pages: {projects, activeProjectId, updateContentProjectSettings, loadPages, setActiveProjectId, createProjectsByName}}} = useStore('');
  const [activeOption, setActiveOption] = useState('');
  const [selectedSection, setSelectedSection] = useState('general');
  const [useCustomToneOfVoice, setUseCustomToneOfVoice] = useState<boolean>(false);
  const [runUseEffect, setRunUseEffect] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [targetUrl, setTargetUrl] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [gbpUrl, setGbpUrl] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [mediaContactName, setMediaContactName] = useState('');
  const [mediaContactEmail, setMediaContactEmail] = useState('');
  const [mediaContactPhone, setMediaContactPhone] = useState('');
  const {contentOptimizer: {pages: {isFolderSettingsFormValid}}} = useStore('');
  const router = useRouter();


  const onSubmit = async values => {
    if (isFolderSettingsFormValid.linksToInclude && isFolderSettingsFormValid.companyInfo) {
      try {
        const requiredInternalLink = linksToIncludeState?.items?.filter(item => item.required && item?.value.trim() !== '').map(item => ({url: item?.value, anchor: item?.anchorText || null}));
        const optionalInternalLink = linksToIncludeState?.items?.filter(item => !item.required && item?.value.trim() !== '').map(item => ({url: item?.value, anchor: item?.anchorText || null}));
        const requiredExternalLink = externalLinksToIncludeState?.items?.filter(item => item.required && item?.value.trim() !== '').map(item => ({url: item?.value, anchor: item?.anchorText || null}));
        const optionalExternalLink = externalLinksToIncludeState?.items?.filter(item => !item.required && item?.value.trim() !== '').map(item => ({url: item?.value, anchor: item?.anchorText || null}));
        const faqSectionValue = values.enable_faq;
        const payloadValues = {...values, images_engine: 'midjourney', tone_of_voice: values?.tone_of_voice || 'Professional', excluded_focusterms: values?.excluded_focusterms || [], point_of_view: values?.point_of_view?.toLowerCase(),
          company_name: companyName,
          website_url: websiteUrl,
          target_url: targetUrl,
          business_address: businessAddress,
          gbp_url: gbpUrl,
          email: email,
          phone: phone,
          media_contact_name: mediaContactName,
          media_contact_email: mediaContactEmail,
          media_contact_phone: mediaContactPhone,
          ...((faqSectionValue && faqSectionValue.toLowerCase() === 'short') && {'use_faq_short_answers': true}),
          ...((faqSectionValue && faqSectionValue.toLowerCase() === 'long') && {'use_faq_long_answers': true}),
        };
        if (payloadValues.name === 'All Articles') {
          delete payloadValues.name;
        }

        if (values?.search_intent && Array.isArray(values.search_intent) && values?.search_intent?.length) {
          payloadValues['search_intent'] = values.search_intent.join(', ');
        } else {
          delete payloadValues['search_intent'];
        }

        for (const key in payloadValues) {
          if (!['excluded_focusterms', 'domain', 'global_background']?.includes(key)) {
            if ((Array.isArray(payloadValues[key])) && !payloadValues[key].length) {
              delete payloadValues[key];
            } else if (payloadValues[key] === null || payloadValues[key] === '') {
              delete payloadValues[key];
            }
          }
        }

        const prePayload = {
          required_internal_links: requiredInternalLink,
          optional_internal_links: optionalInternalLink,
          required_external_links: requiredExternalLink,
          optional_external_links: optionalExternalLink,
        };

        if (terms?.length) {
          setTermsToExclude(prev => {
            const result = [...prev, ...terms.split(',')];
            payloadValues['excluded_focusterms'] = result;
            return result;
          });
          setTerms('');
        }

        if (isEdit) {
          await updateContentProjectSettings({payload: {...payloadValues, ...prePayload}, projectId: activeProjectId});
          await updateUserAiSettings({language: values.language});
          notification.success('', 'Folder settings updated successfully');
          await loadPages(false, activeProjectId);
        } else {
          const res = await createProjectsByName(payloadValues?.name);
          const filteredUpdatedObj = res.find(item => item.name == payloadValues?.name);
          const projectid = filteredUpdatedObj?.id;
          await updateContentProjectSettings({payload: {...payloadValues, ...prePayload}, projectId: projectid});
          notification.success('', 'Folder created successfully');
          await loadPages(false, projectid);
          setActiveProjectId(projectid);
        }
        setUseCustomToneOfVoice(false);
        onClose();
      } catch (error) {
        const errorMessage = apiError(error) as string;
        notification.error('', errorMessage, false, 'OK');
      }
    }
  };
  useEffect(() => {
    const filteredProject = projects.find(item => item.id === activeProjectId);
    setSelectedSection('general');
    if (isEdit) {
      setCompanyName(filteredProject?.companyInfo?.companyName);
      setWebsiteUrl(filteredProject?.companyInfo?.websiteUrl);
      setTargetUrl(filteredProject?.companyInfo?.targetUrl);
      setBusinessAddress(filteredProject?.companyInfo?.businessAddress);
      setGbpUrl(filteredProject?.companyInfo?.gbpUrl);
      setEmail(filteredProject?.companyInfo?.email);
      setPhone(filteredProject?.companyInfo?.phone);
      setMediaContactName(filteredProject?.companyInfo?.mediaContactName);
      setMediaContactEmail(filteredProject?.companyInfo?.mediaContactEmail);
      setMediaContactPhone(filteredProject?.companyInfo?.mediaContactPhone);
    }
  }, []);

  const initialValues = () => {
    if (activeProjectId === 0) return {images_engine: 'Midjourney'};
    const filteredProject = projects.find(item => item.id === activeProjectId);
    if (filteredProject) {
      const searchIntent = filteredProject?.defaultAiSettings?.searchIntent;
      let result;
      if (searchIntent) {
        result = searchIntent?.split(',')?.map(item => item.trim())?.filter(item => item !== '');
      }

      const values = {
        name: filteredProject?.name,
        domain: filteredProject?.domain,
        website_purpose: filteredProject?.defaultAiSettings?.websitePurpose,
        niche: filteredProject?.defaultAiSettings?.niche,
        default_audience: filteredProject?.defaultAiSettings?.defaultAudience,
        search_intent: result || [],
        intent: filteredProject?.defaultAiSettings?.intent,
        global_background: filteredProject?.globalBackground,
        llm: filteredProject?.defaultAiSettings?.llm || 'gpt',
        language: filteredProject?.defaultAiSettings?.language || 'en',
        point_of_view: filteredProject?.defaultAiSettings?.pointOfView || 'Third person',
        act_as_who: filteredProject?.defaultAiSettings?.actAsWho,
        reading_level: filteredProject?.defaultAiSettings?.readingLevel || '8th & 9th grade',
        length: filteredProject?.defaultAiSettings['length'] || 'Short',
        writing_style: filteredProject?.defaultAiSettings?.writingStyle,
        generate_metadata: filteredProject?.defaultAiSettings?.generateMetadata || true,
        disable_skinny_paragraph: filteredProject?.defaultAiSettings?.disableSkinnyParagraph || true,
        enable_key_takeaways: filteredProject?.defaultAiSettings?.enableKeyTakeaways || true,
        bold_terms_to_include: filteredProject?.defaultAiSettings?.boldTermsToInclude || true,
        use_list: filteredProject?.defaultAiSettings?.useList || true,
        use_table: filteredProject?.defaultAiSettings?.useTable || true,
        disable_conclusion: filteredProject?.defaultAiSettings?.disableConclusion,
        excluded_focusterms: filteredProject?.excludedFocusterms || [],
        generate_hero_image: filteredProject?.defaultAiSettings?.generateHeroImage,
        generate_images: filteredProject?.defaultAiSettings?.generateImages,
        images_engine: 'Midjourney',
        images_aspect_ratio: filteredProject?.defaultAiSettings?.imagesAspectRatio || '8:3',
        wp_website_id: filteredProject?.wpWebsiteId,
        wp_post_status: filteredProject?.wpPostStatus || 'draft',
        wp_post_type: filteredProject?.wpPostType || 'post',
        wp_post_categories: filteredProject?.wpPostCategories,
        tone_of_voice: filteredProject?.defaultAiSettings?.toneOfVoice || 'Professional',
        wp_auto_upload: filteredProject?.wpAutoUpload || false,
        enable_faq: filteredProject?.defaultAiSettings?.enableFaq?.toLowerCase() || 'short',
      };
      writingLeftOptions.forEach(item => {
        values[item.key] = filteredProject?.defaultAiSettings[snakeToCamel(item.key)];
      });
      writingRightOptions.forEach(item => {
        values[item.key] = filteredProject?.defaultAiSettings[snakeToCamel(item.key)];
      });

      const defaultValues = {
        name: '',
        domain: '',
        website_purpose: '',
        niche: '',
        default_audience: '',
        search_intent: [],
        intent: '',
        global_background: '',
        llm: 'gpt',
        language: 'en',
        point_of_view: 'Third person',
        act_as_who: '',
        reading_level: '8th & 9th grade',
        length: 'Short',
        writing_style: '',
        generate_metadata: true,
        disable_skinny_paragraph: true,
        enable_key_takeaways: true,
        use_list: true,
        use_table: true,
        excluded_focusterms: [],
        generate_hero_image: true,
        generate_images: true,
        images_engine: 'Midjourney',
        images_aspect_ratio: '8:3',
        wp_website_id: null,
        wp_post_status: 'draft',
        wp_post_type: 'post',
        wp_post_categories: [],
        tone_of_voice: 'Professional',
        bold_terms_to_include: true,
        wp_auto_upload: false,
        enable_faq: 'short',
      };
      return isEdit ? values : defaultValues;
    } else return {images_engine: 'Midjourney'};
  };
  const handleSelectTab = (title: string) => {
    const sectionElement = document.getElementById(title);
    if (sectionElement) {
      sectionElement.scrollIntoView({behavior: 'smooth'});
    }
    setRunUseEffect(false);
    setSelectedSection(title);
    setTimeout(() => {
      setRunUseEffect(true);
    }, 500);
  };

  useEffect(() => {
    const inViewTitle = navigation.find(i => i?.slug == activeOption)?.slug;
    if (activeOption && runUseEffect) setSelectedSection(inViewTitle);
  }, [activeOption]);

  return (<>
    {isInTab ? <></> : <HeadingContainer>
      <ModalHeading>Folder Settings</ModalHeading>
      <FontAwesomeIcon onClick={() => {
        onClose();
      }} icon={faXmark} color='#A3A4A4' />
    </HeadingContainer>}
    {(getOttoV2Project?.isFrozen && router?.pathname?.includes('otto-page-v2')) ? <FreezeBanner>
      <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
      <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
             The changes on this site are frozen. You will be unable to make any modifications.
      </div>
    </FreezeBanner> : ''}
    <div>
      <Row gutter={0} style={{flexWrap: 'nowrap'}}>
        <Col span={7}>
          <div>
            {navigation.map(item => {
              if (item?.slug === 'company_info' && !isLinkgraph) return null;
              return (
                <Option key={item.id} isActive={selectedSection === item.slug}>
                  <FontAwesomeIcon icon={item.icon} />
                  <a href={`#${item.slug}`} onClick={e => {
                    e.preventDefault();
                    handleSelectTab(item.slug);
                  }}>
                    {item.label}
                  </a>
                  {item?.slug === 'company_info' && <LargeBadge alpha/>}
                </Option>
              );
            })}
          </div>
        </Col>
        <Col span={17}>
          <Form
            form={form}
            onFinish={onSubmit}
            initialValues={initialValues()}
            key={formKey}
          >
            <StyledCol id='scrollable-column' style={{maxHeight: isInTab && '620px'}}>

              <GeneralSection form={form} selectedSection={selectedSection} setActiveOption={setActiveOption} currentProjectData={currentProjectData} />
              <SectionDivider />
              <WritingSection
                form={form}
                selectedToneOfVoice={selectedToneOfVoice}
                setSelectedToneOfVoice={setSelectedToneOfVoice}
                selectedSection={selectedSection}
                useCustomToneOfVoice={useCustomToneOfVoice}
                setUseCustomToneOfVoice={setUseCustomToneOfVoice}
                setActiveOption={setActiveOption}
              />
              <SectionDivider />
              <TermsToExclude
                form={form}
                termsToExclude={termsToExclude}
                setTermsToExclude={setTermsToExclude}
                terms={terms}
                setTerms={setTerms}
                selectedSection={selectedSection}
                setActiveOption={setActiveOption}
              />
              <SectionDivider />
              <ImagesSection
                form={form}
                selectedSection={selectedSection}
                setActiveOption={setActiveOption}
              />
              <SectionDivider />
              <LinksToIncludeSection
                linksToIncludeState={linksToIncludeState}
                setLinksToIncludeState={setLinksToIncludeState}
                externalLinksToIncludeState={externalLinksToIncludeState}
                setExternalLinksToIncludeState={setExternalLinksToIncludeState}
                selectedSection={selectedSection}
                setActiveOption={setActiveOption}
              />
              {isLinkgraph && <><SectionDivider />
                <CompanyInfoSection
                  selectedSection={selectedSection}
                  setActiveOption={setActiveOption}
                  setCompanyName={setCompanyName}
                  companyName={companyName}
                  setWebsiteUrl={setWebsiteUrl}
                  websiteUrl={websiteUrl}
                  setTargetUrl={setTargetUrl}
                  targetUrl={targetUrl}
                  setBusinessAddress={setBusinessAddress}
                  businessAddress={businessAddress}
                  setGbpUrl={setGbpUrl}
                  gbpUrl={gbpUrl}
                  setEmail={setEmail}
                  email={email}
                  setPhone={setPhone}
                  phone={phone}
                  setMediaContactName={setMediaContactName}
                  mediaContactName={mediaContactName}
                  mediaContactEmail={mediaContactEmail}
                  setMediaContactEmail={setMediaContactEmail}
                  setMediaContactPhone={setMediaContactPhone}
                  mediaContactPhone={mediaContactPhone}
                /></>}
              <SectionDivider />
              <WpSection
                form={form}
                wpId={wpId}
                setWpId={setWpId}
                selectedSection={selectedSection}
                setActiveOption={setActiveOption}
              />
            </StyledCol>
            {isInTab ? <></> : <Footer
              isEdit={isEdit}
              onClose={onClose}
              setVisible={setVisible}
              setIsEdit={setIsEdit}
            />}
          </Form>
        </Col>
      </Row>
    </div>
  </>
  );
};

export default observer(ModalContent);
