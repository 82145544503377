import {faCirclePlay} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useRef, useState} from 'react';
import styled from 'styled-components';


export const VideoWrapper: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [controls, setControls] = useState(false);
  const [animating, setAnimating] = useState(false);

  const onPlayButton = () => {
    if (videoRef.current) {
      setAnimating(true);
      setControls(true);
      videoRef.current.play();
      setTimeout(() => {
        setAnimating(false);
      }, 5000);
    }
  };
  return (
    <StyledVideoWrapper>
      <div className={`video-wrapper ${animating ? 'pulsatePurple' : ''}`}>
        <video ref={videoRef} className='video' width={'100%'} height={'100%'} controls={controls} muted>
          <source src={'https://searchatlas.com/wp-content/uploads/2024/06/OTTO_WALKTHROUGH_V002-2.mp4'} type='video/mp4' />
        Your browser does not support the video tag.
        </video>
        {!controls && <FontAwesomeIcon className='paly-icon' icon={faCirclePlay} color='#ffffff' fontSize={54} onClick={onPlayButton}/>}
      </div>
    </StyledVideoWrapper>
  );
};

const StyledVideoWrapper = styled.div`
  width: 370px;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/img/otto-tutorial-video-bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .video-wrapper {
    position: relative;
    box-shadow: rgba(256, 256, 256, 0.90) 0px 10px 50px;
    background: #2c2038;
    width: 240px;
    height: 135px;
    border-radius: 12px;
    overflow: hidden;
    .video {
      
    }
    .paly-icon {
      position: absolute;
      top: 29%;
      right: 38%;
      z-index: 10;
      &:hover {
        cursor: pointer;
      }
    }
    &.pulsatePurple {
      animation: pulsatePurple 2.5s 1;
    }
    @keyframes pulsatePurple {
      0% {
        box-shadow: 0px 0px 0px 0px #7F4EAD96;
      }
    
      65% {
        box-shadow: 0px 0px 0px 4px #7F4EAD96;
      }
      
      100% {
        box-shadow: 0px 0px 0px 6px #7F4EAD96;
      }
    }
  }
`;
