import {linkgraphDomains} from '@/utils/router';
import {getDomain} from '@/utils/url';

export const checked = '/img/icon/map_checked_node.png';
export const unChecked = '/img/icon/map_unchecked_node.png';

export const ottoScriptLink = uuid => {
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
  const base64URL = 'data:text/javascript;base64,' + Buffer.from(`var script = document.createElement("script");script.src = 'https://${isWhiteLabelDomain ? currentDomain : 'dashboard.searchatlas.com'}/scripts/dynamic_optimization.js';document.head.appendChild(script);`).toString('base64');
  const uuidStr = uuid ? `data-uuid='${uuid}` : '';
  return `<script type='text/javascript' id='sa-dynamic-optimization' ${uuidStr} src='${base64URL}'></script>`;
};

export const isNodeSelected = (val, data) => {
  const clone = data as any;
  if (clone) {
    for (let index = 0; index < clone?.children?.length; index++) {
      if (clone.children[index].name === val) {
        if (clone.children[index].isSelected) {
          clone.children[index].isSelected = false;
          clone.children[index].symbol = `image://${unChecked}`;
          for (let j = 0; j < clone.children[index]?.children?.length; j++) {
            if (clone.children[index]?.children[j].children?.length) {
              clone.children[index].children[j].isSelected = false;
              clone.children[index].children[j].itemStyle.color = '#D7D7D7';
              clone.children[index].children[j].itemStyle.borderColor = '#D7D7D7';
              clone.children[index].children[j].lineStyle.color = '#D7D7D7';
              clone.children[index].children[j].symbol = `image://${unChecked}`;
              for (let k = 0; k < clone.children[index]?.children[j].children?.length; k++) {
                clone.children[index].children[j].children[k].isSelected = false;
                clone.children[index].children[j].children[k].itemStyle.color = '#D7D7D7';
                clone.children[index].children[j].children[k].itemStyle.borderColor = '#D7D7D7';
                clone.children[index].children[j].children[k].lineStyle.color = '#D7D7D7';
                clone.children[index].children[j].children[k].symbol = `image://${unChecked}`;
              }
            } else {
              clone.children[index].children[j].isSelected = false;
              clone.children[index].children[j].itemStyle.color = '#D7D7D7';
              clone.children[index].children[j].itemStyle.borderColor = '#D7D7D7';
              clone.children[index].children[j].lineStyle.color = '#D7D7D7';
              clone.children[index].children[j].symbol = `image://${unChecked}`;
            }
          }
        } else {
          for (let j = 0; j < clone.children[index]?.children?.length; j++) {
            if (clone.children[index]?.children[j].children?.length) {
              clone.children[index].children[j].isSelected = true;
              clone.children[index].children[j].itemStyle.color = '#7F4EAD';
              clone.children[index].children[j].itemStyle.borderColor = '#7F4EAD';
              clone.children[index].children[j].lineStyle.color = '#7F4EAD';
              clone.children[index].children[j].symbol = `image://${checked}`;
              for (let k = 0; k < clone.children[index]?.children[j].children?.length; k++) {
                clone.children[index].children[j].children[k].isSelected = true;
                clone.children[index].children[j].children[k].itemStyle.color = '#7F4EAD';
                clone.children[index].children[j].children[k].itemStyle.borderColor = '#7F4EAD';
                clone.children[index].children[j].children[k].lineStyle.color = '#7F4EAD';
                clone.children[index].children[j].children[k].symbol = `image://${checked}`;
              }
            } else {
              clone.children[index].children[j].isSelected = true;
              clone.children[index].children[j].itemStyle.color = '#7F4EAD';
              clone.children[index].children[j].itemStyle.borderColor = '#7F4EAD';
              clone.children[index].children[j].lineStyle.color = '#7F4EAD';
              clone.children[index].children[j].symbol = `image://${checked}`;
            }
          }
          clone.children[index].isSelected = true;
          clone.children[index].symbol = `image://${checked}`;
        }
      } else {
        for (let j = 0; j < clone.children[index]?.children?.length; j++) {
          if (clone.children[index]?.children[j].children?.length) {
            if (clone.children[index]?.children[j].name === val) {
              if (clone.children[index]?.children[j].isSelected) {
                clone.children[index].children[j].isSelected = false;
                clone.children[index].children[j].itemStyle.color = '#D7D7D7';
                clone.children[index].children[j].itemStyle.borderColor = '#D7D7D7';
                clone.children[index].children[j].lineStyle.color = '#D7D7D7';
                clone.children[index].children[j].symbol = `image://${unChecked}`;
                for (let k = 0; k < clone.children[index]?.children[j].children?.length; k++) {
                  clone.children[index].children[j].children[k].isSelected = false;
                  clone.children[index].children[j].children[k].itemStyle.color = '#D7D7D7';
                  clone.children[index].children[j].children[k].itemStyle.borderColor = '#D7D7D7';
                  clone.children[index].children[j].children[k].lineStyle.color = '#D7D7D7';
                  clone.children[index].children[j].children[k].symbol = `image://${unChecked}`;
                }
              } else {
                for (let k = 0; k < clone.children[index]?.children[j].children?.length; k++) {
                  clone.children[index].children[j].children[k].isSelected = true;
                  clone.children[index].children[j].children[k].itemStyle.color = '#7F4EAD';
                  clone.children[index].children[j].children[k].itemStyle.borderColor = '#7F4EAD';
                  clone.children[index].children[j].children[k].lineStyle.color = '#7F4EAD';
                  clone.children[index].children[j].children[k].symbol = `image://${checked}`;
                }
                clone.children[index].children[j].isSelected = true;
                clone.children[index].children[j].itemStyle.color = '#7F4EAD';
                clone.children[index].children[j].itemStyle.borderColor = '#7F4EAD';
                clone.children[index].children[j].lineStyle.color = '#7F4EAD';
                clone.children[index].children[j].symbol = `image://${checked}`;
              }
              const isAllChecked = clone?.children[index]?.children?.filter(data => data?.isSelected === false);
              if (isAllChecked?.length) {
                clone.children[index].isSelected = false;
                clone.children[index].symbol = `image://${unChecked}`;
              } else {
                clone.children[index].isSelected = true;
                clone.children[index].symbol = `image://${checked}`;
              }
            } else {
              for (let k = 0; k < clone.children[index]?.children[j]?.children?.length; k++) {
                if (Number(clone.children[index]?.children[j].children[k]?.value) === Number(val)) {
                  if (clone.children[index]?.children[j].children[k]?.isSelected) {
                    clone.children[index].children[j].children[k].isSelected = false;
                    clone.children[index].children[j].children[k].itemStyle.color = '#D7D7D7';
                    clone.children[index].children[j].children[k].itemStyle.borderColor = '#D7D7D7';
                    clone.children[index].children[j].children[k].lineStyle.color = '#D7D7D7';
                    clone.children[index].children[j].children[k].symbol = `image://${unChecked}`;
                  } else {
                    clone.children[index].children[j].children[k].isSelected = true;
                    clone.children[index].children[j].children[k].itemStyle.color = '#7F4EAD';
                    clone.children[index].children[j].children[k].itemStyle.borderColor = '#7F4EAD';
                    clone.children[index].children[j].children[k].lineStyle.color = '#7F4EAD';
                    clone.children[index].children[j].children[k].symbol = `image://${checked}`;
                  }
                }
                const isAllChecked = clone?.children[index].children[j]?.children?.filter(data => data?.isSelected === false);
                if (isAllChecked?.length) {
                  clone.children[index].children[j].isSelected = false;
                  clone.children[index].children[j].itemStyle.color = '#D7D7D7';
                  clone.children[index].children[j].itemStyle.borderColor = '#D7D7D7';
                  clone.children[index].children[j].lineStyle.color = '#D7D7D7';
                  clone.children[index].children[j].symbol = `image://${unChecked}`;
                } else {
                  clone.children[index].children[j].isSelected = true;
                  clone.children[index].children[j].itemStyle.color = '#7F4EAD';
                  clone.children[index].children[j].itemStyle.borderColor = '#7F4EAD';
                  clone.children[index].children[j].lineStyle.color = '#7F4EAD';
                  clone.children[index].children[j].symbol = `image://${checked}`;
                }
              }
            }
          } else {
            if (Number(clone.children[index]?.children[j]?.value) === Number(val)) {
              if (clone.children[index]?.children[j]?.isSelected) {
                clone.children[index].children[j].isSelected = false;
                clone.children[index].children[j].itemStyle.color = '#D7D7D7';
                clone.children[index].children[j].itemStyle.borderColor = '#D7D7D7';
                clone.children[index].children[j].lineStyle.color = '#D7D7D7';
                clone.children[index].children[j].symbol = `image://${unChecked}`;
              } else {
                clone.children[index].children[j].isSelected = true;
                clone.children[index].children[j].itemStyle.color = '#7F4EAD';
                clone.children[index].children[j].itemStyle.borderColor = '#7F4EAD';
                clone.children[index].children[j].lineStyle.color = '#7F4EAD';
                clone.children[index].children[j].symbol = `image://${checked}`;
              }
            }
            const isAllChecked = clone?.children[index]?.children?.filter(data => data?.isSelected === false);
            if (isAllChecked?.length) {
              clone.children[index].isSelected = false;
              clone.children[index].symbol = `image://${unChecked}`;
            } else {
              clone.children[index].isSelected = true;
              clone.children[index].symbol = `image://${checked}`;
            }
          }
        }
      }
    }
    return clone;
  }
};

export const getLeafNodes = (data: any) => {
  if (!Array.isArray(data?.children) || data.children.length === 0) {
    return [data];
  }
  return data.children.flatMap((child: any) => getLeafNodes(child));
};

export const spaceBetweenNodes = topicalMapDataState => {
  if (getLeafNodes(topicalMapDataState)?.length > 1) {
    const nodesNum = getLeafNodes(topicalMapDataState)?.length;
    if (nodesNum > 0) {
      if (nodesNum > 0 && nodesNum <= 10) {
        return '-5%';
      } else if (nodesNum > 10 && nodesNum <= 20) {
        return '-50%';
      } else if (nodesNum > 20 && nodesNum <= 30) {
        return '-130%';
      } else if (nodesNum > 30 && nodesNum <= 40) {
        return '-200%';
      } else if (nodesNum > 40 && nodesNum <= 50) {
        return '-300%';
      } else if (nodesNum > 50 && nodesNum <= 60) {
        return '-400%';
      } else if (nodesNum > 60 && nodesNum <= 70) {
        return '-500%';
      } else if (nodesNum > 70 && nodesNum <= 80) {
        return '-600%';
      } else if (nodesNum > 80 && nodesNum <= 90) {
        return '-700%';
      } else if (nodesNum > 90 && nodesNum <= 100) {
        return '-800%';
      } else if (nodesNum > 100 && nodesNum <= 200) {
        return '-1600%';
      } else if (nodesNum > 200 && nodesNum <= 300) {
        return '-1950%';
      } else if (nodesNum > 300 && nodesNum <= 400) {
        return '-2600%';
      } else if (nodesNum > 400 && nodesNum <= 500) {
        return '-3250%';
      } else if (nodesNum > 500 && nodesNum <= 600) {
        return '-3950%';
      } else if (nodesNum > 600 && nodesNum <= 700) {
        return '-4650%';
      } else if (nodesNum > 700 && nodesNum <= 800) {
        return '-5250%';
      } else if (nodesNum > 800 && nodesNum <= 900) {
        return '-5950%';
      } else if (nodesNum > 900 && nodesNum <= 1000) {
        return '-6250%';
      } else {
        return '-6090%';
      }
    }
  } else {
    return '100%';
  }
};

export const handleOnChangeFilterTab = (key: string, initialData: any) => {
  const filterChildren = node => {
    const filteredChildren = node.children?.map(child => {
      const filteredChild = filterChildren(child);
      if (filteredChild.children?.length) {
        const matchingChildren = filteredChild.children.filter(grandchild => grandchild[key]);
        if (matchingChildren.length) {
          return {...filteredChild, children: matchingChildren};
        }
      } else if (
        filteredChild[key]
      ) {
        return filteredChild;
      }
      return null;
    }).filter(Boolean);
    return {...node, children: filteredChildren};
  };
  return initialData
    .map(filterChildren)
    .filter(node => node.children?.length);
};
