import {Tooltip, Spin, Dropdown, Input, Collapse} from 'antd';
import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faSortDown, faCheckCircle, faPen, faTrashXmark} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, addProtocolToDomain} from '@/utils/url';
import {notification} from '@/utils/notifications';
import {openUrl} from '@/utils/router';
import {getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {ErrorDiv, BackButton, ButtonsWrapper, DeleteButton, DescriptionWrapper, MiddleInputWrapper, ModalWrapper, NlpTableWrapper, OnlyHeaderTAble, PaginationStyled, PopUpWrapper, StatusContainer, StatusMenu, StatusWrapper, StyledIssuesCollapse, StyledIssuesTable, StyledTrashIcon, UrlWrapper, Wrapper, PurpleCheckbox} from '../../../../style';
import {StyledMenu} from '../../../../Utils/styledMenu';
import {canDeploy, disableCheck, getDeployStatus, getStatusText, showNotificationMess} from '../../../../Constants/functions';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {debounce} from 'lodash';
import EditTargetKeywordsModal from '../../../../Modals/editTargetKeywordsModal';
import {termQAError} from '../../../../Constants';
import {TableTopBar} from '../../../tableTopBar';
import {Button, Modal} from '@/components/common-components/components';
import {StyledSpin} from '../../../../Utils/styledSpin';
import {newNotification} from '@/utils/notification-v3';
import {timeNotification} from '@/utils/notification-timer';
import {BulkActionBar} from '../../../bulkActionBar';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';

let ReactJson = null;
if (typeof document !== 'undefined') {
  import('react-json-view').then(module => {
    ReactJson = module.default;
  });
}
interface Props {
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  setOttoProject: (value: number) => void;
  issueTable: string;
  setCurrentIssueType: (value: string) => void;
  setOpenBulkPagesModal: Dispatch<SetStateAction<boolean>>;
  setAddBulkPagesFrom?: Dispatch<SetStateAction<string>>;
}

export const NlpTerms = observer(({componentIssueType, setPageChanged, setIssueTable, issueTable, setOttoProject, setCurrentIssueType, setOpenBulkPagesModal, setAddBulkPagesFrom}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    deployOttoUrls,
    setIssueTypeSelected,
    setIsDeploying,
    loadOttoV2Project,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    loadIssueTableData,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    setOttoSearchTerm,
    isActiveKeys,
    selectedIssue,
    selectedCategory,
    addSourcePage,
    deploySubOttoUrls,
    deleteUrlIssue,
    setNlpPagination,
  },
  } = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [currentProject, setCurrentProject] = useState(null);
  const [urlId, setUrlId] = useState<any>(-1);
  const [ottoUrls, setOttoUrls] = useState([]);
  const [qaDeleteVisible, setQADeleteVisible] = useState(false);
  const [currentDelId, setCurrentDelId] = useState(-1);
  const [termQAErrorState, setTermQAErrorState] = useState(termQAError);
  const [addNLPTerms, setAddNLPTerms] = useState(false);
  const [deleteItem, setDeleteItem] = useState<any>(-1);
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [addNLPTermsInput, setAddNLPTermsInput] = useState('');
  const [nLPTermsError, setNLPTermsError] = useState(false);
  const [addingNLPTerms, setAddingNLPTerms] = useState(false);
  const [subUrlId, setSubUrlId] = useState<any>(-1);
  const [deleteIssueIds, setDeleteIssueIds] = useState<any>({parent: null, child: null});
  const [openModal, setOpenModal] = useState(false);
  const [modalConf, setModalConf] = useState({
    visible: false,
    keywords: [],
    path: '',
    id: null,
    issueType: '',
  });
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [deployLoading, setDeployLoading] = useState('');
  const {width} = useWindowSize();
  const jsonViewRef = useRef(null);
  const [openedIndex, setOpenedIndex] = useState<any>(null);
  const termInputRef = useRef(null);
  const [showBulkBanner, setShowBulkBanner] = useState<boolean>(false);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;
  const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
  const antBlueIcon = <LoadingOutlined style={{fontSize: 16, color: '#2D6CCA'}} spin />;
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));
  const [page, setPage] = useState(1);

  const getOttoUrlAgainstIssueType = urls => {
    const filteredOttoUrls = urls ? urls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);

    return url;
  };

  useEffect(() => {
    if (getOttoUrls) {
      const url = getOttoUrlAgainstIssueType(getOttoUrls);
      if (url?.page === page) setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    if (filteredData.length && selectAll) {
      const pageArray = filteredData.map(data => data?.id);
      setSelectedPages(pageArray);
    }
  }, [ottoUrls, selectAll]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  const deployOttoUrl = async (id, toDeploy, issueType) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    setUrlId(`${id}nlp_terms`);
    try {
      const data = {toDeploy, issueType, uuid, ottoUrls: [id]};
      await deployOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change Rolled Back', 2, 'rollback');
      }
      setUrlId(-1);
    } catch (error) {
      setUrlId(-1);
      return Promise.reject(error);
    } finally {
      setDeployLoading('');
    }
  };
  const deployOttoSection = async (toDeploy: boolean) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    try {
      await deployOttoUrls({toDeploy, issueType: url?.issueType, uuid});
      setPageChanged(true);
      setIssueTypeSelected(url?.issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification('Changes Deployed Successfully', 2, 'deploy');
      } else {
        newNotification('Changes Rolled Back', 2, 'rollback');
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const showConfirmationPopup = record => {
    if (deployLoading !== 'Undeploy all' && deployLoading !== 'Deploy all') {
      setCurrentRecord(record);
      setIsDeploying(false);
      setOpenModal(true);
    }
  };
  const deploySubOttoUrl = async (id, index, toDeploy, issueType ) => {
    if (!showNotificationMess(currentProject)) {
      return;
    }
    setSubUrlId(index);
    try {
      const data = {toDeploy, issueType, uuid};
      data['itemIndex'] = index;
      data['ottoUrls'] = [id];
      await deploySubOttoUrls(data);
      setPageChanged(true);
      setIssueTypeSelected(issueType);
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      if (toDeploy) {
        newNotification(issueType == 'page_title' ? (currentProject?.isActive ? '1 Change deployed' : '1 Change enabled') : '1 Change deployed', 2, 'deploy');
      } else {
        newNotification('1 Change rolled back', 2, 'rollback');
      }
      setSubUrlId(-1);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      setSubUrlId(-1);
    }
  };
  const onAddNLPTerm = async record => {
    setAddingNLPTerms(true);
    const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
    const payload = {
      issue_type: record?.issueType,
      otto_url_id: record?.id,
      object_key: addNLPTermsInput,
    };
    try {
      await addSourcePage(uuid, payload);
      await loadIssueTableData({
        uuid: getOttoV2Project?.uuid,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        issue_type: 'nlp_terms',
        otto_project: getOttoV2Project?.id,
      });
      notification.success('Keyword added successfully.', '', 2);
      setAddNLPTerms(false);
      setAddNLPTermsInput('');
      setAddingNLPTerms(false);
    } catch (error) {
      setAddNLPTerms(false);
      setAddingNLPTerms(false);
    }
  };
  const onDropdownVisibleChange = (visible, id, recordIndex) => {
    setCurrentDelId(id);
    setOpenedIndex(visible ? recordIndex : null);
    if (!visible) {
      setAddNLPTerms(visible);
      setCurrentDelId(-1);
      setQADeleteVisible(false);
      setTermQAErrorState({...termQAErrorState, question: {...termQAErrorState['question'], state: false}, answer: {...termQAErrorState['answer'], state: false}});
    } else {
      setQADeleteVisible(true);
    }
  };
  const deleteIssue = async (id, itemIndex) => {
    if (deleteItem === -1) {
      setDeleteItem(`${itemIndex}${id}`);
      setDeleteIssueIds({parent: id, child: itemIndex});
      try {
        const onDelayEnd = async () => {
          const payload = {
            'uuid': uuid,
            'issue_type': componentIssueType,
            'item_index': itemIndex,
            'to_delete': true,
          };
          await deleteUrlIssue(id, uuid, payload);
          // notification.success('Issue deleted successfully', '', 2);
          setPageChanged(true);
          setIssueTypeSelected(componentIssueType);
          setIsDeploying(true);
          setDeleteItem(-1);
          if (uuid) {
            loadOttoV2Project(uuid, true);
          }
        };
        const handleUndo = () => {
          setDeleteItem(-1);
          setDeleteIssueIds({parent: null, child: null});
        };
        timeNotification('#FF0000', faTrashXmark, 4, `Issue deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
        setDeleteItem(-1);
      } catch (error) {
        setUrlId(-1);
        setDeleteItem(-1);
      }
    }
  };


  const onDeploy = (record, state) => {
    if (canDeploy(record, record?.issueType)) {
      deployOttoUrl(record?.id, !state, record?.issueType);
      if (state) {
        setDeployLoading('Undeploy all');
      } else {
        setDeployLoading('Deploy all');
      }
    }
  };


  const nlpMenu = (data, index) => {
    const antUrlIcon = <LoadingOutlined style={{fontSize: 16, color: '#2AC155'}} spin />;
    const updatedData = data?.values?.map(item => ({
      ...item,
      issueType: data?.issueType,
      id: data?.id,
    }));
    const nlpTermColumns = [
      {
        title: (<div className='column-title'>
          STATUS
        </div>),
        dataIndex: 'status',
        key: 'status',
        width: 110,
        render: (_, record, index) => {
          return (<>
            <FreezeWrapper removeTooltip={currentProject?.pixelTagState === 'installed' && currentProject?.isEngaged ? record?.isApproved : false}>
              <StatusWrapper
                style={data?.id == deleteIssueIds?.parent && record?.itemIndex == deleteIssueIds?.child ? {display: 'none'} : {}}
                status={currentProject?.pixelTagState === 'installed' && currentProject?.isEngaged ? record?.isApproved : false}
                isDisabled={subUrlId !== -1}
                onClick={() => {
                  if (subUrlId !== -1) return;
                  deploySubOttoUrl(record?.id, index, !record?.isApproved, record?.issueType);
                }}
              >
                {subUrlId === index ? <Spin indicator={antUrlIcon} /> :
                  <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={currentProject?.pixelTagState === 'installed' && currentProject?.isEngaged ? record?.isApproved ? `#2AC155` : '#A3A4A4' : '#A3A4A4'} />
                }
                <span>{getStatusText(record?.isApproved, currentProject)}</span>
              </StatusWrapper>
            </FreezeWrapper>
          </>
          );
        },
      },
      {
        title: <div className='column-title'>NLP TERM</div>,
        dataIndex: 'nlp_terms',
        key: 'nlp_terms',
        width: '280px',
        render: (_, record) => {
          return <div className='nlp-term-name' style={data?.id == deleteIssueIds?.parent && record?.itemIndex == deleteIssueIds?.child ? {display: 'none'}: {}}>{record?.name}</div>;
        },
      },
      {
        title: <></>,
        dataIndex: 'trashIcon',
        key: 'trashIcon',
        render: (_, record) => {
          return (
            <div style={{display: data?.id == deleteIssueIds?.parent && record?.itemIndex == deleteIssueIds?.child ? 'none' : 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
              <FreezeWrapper placement='topRight' removeTooltip={currentProject?.pixelTagState === 'installed' && currentProject?.isEngaged ? record?.isApproved : false}>
                <Tooltip title={deleteItem === record?.itemIndex ? 'Deleting':'Delete'}>
                  {
                    deleteItem === `${record?.itemIndex}${record?.id}` ?
                      <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#A3A4A4'}} spin />}/>:
                      <StyledTrashIcon icon={faTrash} fontSize={14} color='#A3A4A4' onClick={() => !ottoUrlLoader && showConfirmationPopup(record)}/>
                  }
                </Tooltip>
              </FreezeWrapper>
            </div>
          );
        },
      },
    ];

    return (
      <StatusContainer style={{padding: 0, width: '520px', borderRadius: '1rem', border: '1px solid #ddd'}}>
        <div style={{padding: '6px 6px 10px 6px'}}>
          <div className='header'>
            <div className='status'>
              <div className='heading'>
                Deployed: {updatedData.filter(data => data?.isApproved)?.length} of {updatedData?.length}
              </div>
              <div className='button-wrap'>
                <FreezeWrapper>
                  <Tooltip title={!canDeploy(data, data?.issueType) && !data?.isActive ? 'Empty fields cannot be deployed.': ''}>
                    <div
                      className='button green'
                      style={{cursor: (!canDeploy(data, data?.issueType) && !data?.isActive) ? 'not-allowed':''}}
                      onClick={() => onDeploy(data, false)}>
                      Deploy all {deployLoading === 'Deploy all' && <StyledSpin />}
                    </div>
                  </Tooltip>
                </FreezeWrapper>
                <FreezeWrapper>
                  <Tooltip title={!canDeploy(data, data?.issueType) && !data?.isActive ? 'Empty fields cannot be deployed.': ''}>
                    <div
                      className='button red'
                      style={{cursor: (!canDeploy(data, data?.issueType) && !data?.isActive) ? 'not-allowed':''}}
                      onClick={() => onDeploy(data, true)}>
                      Undeploy all {deployLoading === 'Undeploy all' && <StyledSpin color={'red'} />}
                    </div>
                  </Tooltip>
                </FreezeWrapper>
              </div>
            </div>
            <div className='button-wrapper'>
              <FreezeWrapper placement='topRight'>
                <div onClick={() => {
                  if (deployLoading !== 'Deploy all' && deployLoading !== 'Undeploy all') {
                    setAddNLPTerms(true);
                    setNLPTermsError(false);
                    setAddNLPTermsInput('');
                    if (termInputRef?.current) {
                      termInputRef.current.scrollTop = 0;
                    }
                  }
                }} style={{cursor: 'pointer', color: '#2D6CCA'}}>+Add Term</div>
              </FreezeWrapper>
            </div>
          </div>
          <OnlyHeaderTAble
            columns={nlpTermColumns}
            loading={ottoUrlLoader}
            dataSource={[{}]}
            pagination={false}
            style={{width: '510px', margin: 0}}
            isInPopOver={true}
            scroll={{y: 300, x: 'auto'}}
          />
          <NlpTableWrapper style={{scrollBehavior: 'smooth'}} ref={index == openedIndex ? termInputRef : null}>
            <MiddleInputWrapper>
              {
                addNLPTerms ? (
                  <div style={{width: '100%'}}>
                    <div style={{display: 'flex', gap: '20px'}}>
                      <Input autoFocus className={'nlp-terms-input'} placeholder={'Enter the Term here...'} value={addNLPTermsInput} onChange={e => {
                        const value = e?.target?.value;
                        setAddNLPTermsInput(e.target.value);
                        if (value?.length <= 200) {
                          setNLPTermsError(false);
                        } else {
                          setNLPTermsError(true);
                        }
                      }}/>
                      <ButtonsWrapper>
                        <Button
                          style={{display: 'flex', alignItems: 'center'}}
                          loading={addingNLPTerms}
                          disabled={addingNLPTerms || nLPTermsError || !addNLPTermsInput.length || deployLoading === 'Deploy all' || deployLoading === 'Undeploy all'}
                          buttonType='blue-button'
                          className={'nlp-terms-add-btn'}
                          type='button'
                          onClick={() => nLPTermsError ? {} : onAddNLPTerm(data)}>Add</Button>
                        <div className={'cross-icon'}
                          onClick={() => {
                            setAddNLPTerms(false);
                            setAddNLPTermsInput('');
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} color='#fff' fontSize={18}/>
                        </div>
                      </ButtonsWrapper>
                    </div>
                    {nLPTermsError ? <ErrorDiv>Term should no longer than 200 characters</ErrorDiv> : <></>}
                  </div>
                ) : <></>
              }
            </MiddleInputWrapper>
            <StyledIssuesTable
              columns={nlpTermColumns}
              showHeader={false}
              dataSource={updatedData}
              pagination={false}
              style={{width: '510px', margin: 0}}
              isInPopOver={true}
              scroll={{y: 'none', x: 'none'}}
            />
          </NlpTableWrapper>
        </div>
      </StatusContainer>
    );
  };

  const nlpSchemaMenu = recommendedSchema => {
    return (
      <StatusMenu>
        <PopUpWrapper style={{width: '510px'}}>
          <div ref={jsonViewRef} className='container'>
            {typeof document !== 'undefined' &&
              Object.keys(recommendedSchema).length ?
              <ReactJson collapseStringsAfterLength={250} src={recommendedSchema} displayDataTypes={false} /> :
              <></>
            }
          </div>
        </PopUpWrapper>
      </StatusMenu>
    );
  };

  const url = getOttoUrlAgainstIssueType(ottoUrls);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  const selectUnselectPages = page => {
    if (selectedPages.includes(page)) {
      setSelectedPages(data => data.filter(item => item !== page));
      setSelectAll(false);
    } else {
      setSelectedPages(data => [...data, page]);
    }
  };

  const columns = [
    {
      title: (<div className='column-title'>
        {<Dropdown overlay={
          <StyledMenu disabled={filteredData?.length == 0} selectedIssueType={url?.issueType} deployOttoSection={deployOttoSection} currentProject={currentProject}/>
        } trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
          <div>
            <span>STATUS</span>
            <FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={12}/>
          </div>
        </Dropdown>
        }
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: '125px',
      render: (_, record) => {
        return (
          <FreezeWrapper removeTooltip={record?.isActive}>
            <Tooltip title={canDeploy(record, record?.issueType) || record?.isActive ? '' : 'Empty fields cannot be deployed.'}>
              <StatusWrapper
                status={record?.isActive}
                onClick={() => ((canDeploy(record, record?.issueType) || record?.isActive)) && deployOttoUrl(record?.id, !record?.isActive, record?.issueType)}
                style={{position: record?.issueType === 'internal_link_suggestions' ? 'absolute': 'relative', top: record?.issueType === 'internal_link_suggestions' && '16px'}}
                isDisabled={(!canDeploy(record, record?.issueType) && !record?.isActive)}
              >
                {(record?.issueType === 'nlp_terms' && urlId === `${record?.id}nlp_terms`) ?
                  <div style={{fontWeight: 400, fontSize: '13px', color: '#2D6CCA'}}>
                    <Spin indicator={antBlueIcon} /> Updating
                  </div> : urlId === record?.id ? <Spin indicator={antUrlIcon} /> :
                    <>
                      <FontAwesomeIcon icon={faCheckCircle} fontSize={20} color={record?.isActive ? `#2AC155` : '#A3A4A4'} />
                      <span>{getStatusText(record?.isActive, currentProject)}</span>
                    </>}
              </StatusWrapper>
            </Tooltip>
          </FreezeWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>PAGE URL</div>,
      dataIndex: 'url',
      key: 'url',
      width: '280px',
      render: (_, record) => {
        const path = (record?.path?.length && record?.path?.includes(domainName) && record?.path[0] === '/') ? record?.path.slice(1) : record?.path;
        return (<div style={{display: 'flex', alignItems: 'center', gap: 10}}>
          {showBulkBanner && <PurpleCheckbox checked={selectedPages.includes(record?.id)} onClick={()=>selectUnselectPages(record?.id)}/>}
          <UrlWrapper onClick={() => path?.includes(domainName) ? openUrl(path?.startsWith('http://') || path?.startsWith('https://') ? path : `https://${path}`, '_blank') : openUrl(`${addProtocolToDomain(domainName)}/${path}`, '_blank')}>
            {record?.path?.includes(domainName) && record?.path[0] === '/' ? record?.path.slice(1) : record?.path}</UrlWrapper>
        </div>);
      },
    },
    {
      title: <div className='column-title'>TARGET KEYWORDS</div>,
      dataIndex: 'targetKeywords',
      key: 'targetKeywords',
      width: '300px',
      render: (_, record) => {
        return (
          <DescriptionWrapper>
            {record?.keywords?.length ? record?.keywords?.map((keyword, idx) => (
              keyword + (idx !== record.keywords.length - 1 ? ', ' : '')
            )) : '-' }
            {record?.nlpStatus=='PENDING' ? <></> : <FreezeWrapper><FontAwesomeIcon
              style={{cursor: 'pointer', marginLeft: '5px'}}
              icon={faPen}
              fontSize={12}
              color='#4E5156'
              onClick={() => {
                setModalConf({visible: true, keywords: record?.keywords, path: `${domainName}${record?.path}`, id: record?.id, issueType: record?.issueType});
              }} /></FreezeWrapper>}
          </DescriptionWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>TERMS</div>,
      dataIndex: 'terms',
      key: 'terms',
      className: 'greenish-column',
      render: (id, record, index) => {
        const deployedValues = record?.values?.filter(value => value?.isApproved === true);
        return (
          record?.nlpStatus=='PENDING' ? <Tooltip title={'Status: Pending'}><Spin indicator={<LoadingOutlined style={{fontSize: 16, color: '#219843'}} spin />} /></Tooltip> : <div style={{width: '100%', maxWidth: '700px', cursor: 'pointer', opacity: disableCheck(record, urlId)}}>
            <Dropdown visible={qaDeleteVisible && currentDelId == record.id} onVisibleChange={e=> onDropdownVisibleChange(e, record.id, index)} overlay={()=> nlpMenu(record, index)} trigger={['click']} placement='bottomLeft' overlayStyle={{top: '798px', boxShadow: '0px 1px 0px 0px #F4F4F4', minWidth: 500, zIndex: 9}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span>Deployed:&nbsp;{deployedValues?.length}&nbsp;of&nbsp;{record?.values?.length}&nbsp;{['nlp_terms'].includes(record.issueType) ? 'terms' : ''}</span>
                <FontAwesomeIcon icon={faSortDown} color='#272222' fontSize={12} style={{marginLeft: 10}} />
              </div>
            </Dropdown>
          </div>
        );
      },
    },
    {
      title: <div className='column-title'>SCHEMA</div>,
      dataIndex: 'schema',
      key: 'schema',
      className: 'greenish-column',
      render: (_, record) => {
        return record?.nlpStatus=='PENDING' ? <Tooltip title={'Status: Pending'}><Spin indicator={<LoadingOutlined style={{fontSize: 16, color: '#219843'}} spin />} /></Tooltip> :
          <div style={{width: '100%', maxWidth: '700px', cursor: 'pointer', opacity: (urlId === `${record?.id}nlp_terms`) && '20%'}}>
            <Dropdown overlay={() => nlpSchemaMenu(record?.recommendedSchema)}
              trigger={['click']}
              placement='bottomLeft'
              overlayStyle={{top: '798px',
                boxShadow: '0px 1px 0px 0px #F4F4F4'}}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span>View Schema</span>
                <FontAwesomeIcon icon={faSortDown} color='#272222' fontSize={12} style={{marginLeft: 10}} />
              </div>
            </Dropdown>
          </div>;
      },
    },
  ];

  const selectAllOnPage = () => {
    setSelectedPages(filteredData.map(data => data?.id));
  };

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, rating?: string, activeKey?: string) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    const categories = issue == 'All' ? issueTypesFrontendMappingV2.find(group=> group.subGroups.filter(g=> g.group == selectedCategory).length).group : issue;
    const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj.group === categories || issueObj.label === categories)?.subGroups?.find(categoryObj => categoryObj?.group === category);
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      const itemObject: any = issueObject?.subGroups?.find((item: any) => item?.group === issueType);
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...((activeKey == 'deployed' || activeKey == 'not_deployed') && {deploy_status: getDeployStatus(activeKey)}),
        };
        if (searchText) {
          params['search'] = searchText;
          setOttoSearchTerm(searchText);
        } else {
          setOttoSearchTerm('');
        }
        if (searchText) {
          searchDebounce(params, itemObject?.isSitewide);
        } else {
          await loadIssueTableData(params, itemObject?.isSitewide);
        }
      }
    }
    setLoadingDetail(false);
  }, []);
  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    const activeKey = issueArray?.length ? isActiveKeys?.find(item => item?.issue == issueArray[0])?.key : 'all';
    setNlpPagination(page, pageSize);
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, searchText, '', activeKey);
  };

  return (
    <>
      <StyledIssuesCollapse
        ghost
        expandIconPosition='right'
        defaultActiveKey={[`${isTableOpen}`]}
        activeKey={[`${isTableOpen}`]}
        onChange={() => {
          if (isTableOpen === 'close') {
            setIsTableOpen('open');
            setIsOpenSearch(false);
          } else {
            setIsTableOpen('close');
            setIsOpenSearch(false);
          }
        }}
      >
        <TableTopBar
          componentIssueType={componentIssueType}
          setPageChanged={setPageChanged}
          setIssueTable={setIssueTable}
          issueTable={issueTable}
          setSearchText={setSearchText}
          searchText={searchText}
          setIsOpenSearch={setIsOpenSearch}
          isOpenSearch={isOpenSearch}
          setIsTableOpen={setIsTableOpen}
          isTableOpen={isTableOpen}
          setOttoProject={setOttoProject}
          setIsActiveFilter={setIsActiveFilter}
          isActiveFilter={isActiveFilter}
          setCurrentIssueType={setCurrentIssueType}
          setOpenBulkPagesModal={setOpenBulkPagesModal}
          setAddBulkPagesFrom={setAddBulkPagesFrom}
          isResponsiveHeader
          setShowBulkBanner={setShowBulkBanner}
        />
        {showBulkBanner ? <BulkActionBar
          count={url?.issueTable?.count}
          setShowBulkBanner={setShowBulkBanner}
          selectedPages={selectedPages}
          setSelectedPages={setSelectedPages}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          selectAllOnPage={selectAllOnPage}
          issueType={url?.issueType}
          currentProject={currentProject}
          setPageChanged={setPageChanged}
        /> : <></>}
        <Collapse.Panel key='open' header={<></>}>
          <StyledIssuesTable
            loading={ottoUrlLoader && ottoIssueType === componentIssueType}
            columns={columns.filter(column => showBulkBanner ? column.key !== 'status' : true)}
            dataSource={filteredData}
            pagination={false}
            scroll={width < 1100 ? {x: 'auto'} : {}}
          />
          <PaginationStyled
            onChange={(page, pageSize) => {
              setPageChanged(true);
              setIssueTable(url?.issueType);
              handlePaginationChange([url?.issueType], page, pageSize);
              saveOttoTablePageSize(componentIssueType, pageSize);
              setPage(page);
              setPageSize(pageSize);
            }}
            total={url?.issueTable?.count}
            pageSize={pageSize}
            current={page}
            showSizeChanger
            pageSizeOptions={['5', '10', '20', '50', '100']}
          />
        </Collapse.Panel>
      </StyledIssuesCollapse>
      <EditTargetKeywordsModal modalConf={modalConf} setModalConf={setModalConf} uuid={uuid} />
      {<Wrapper>
        <Modal
          onClose={() => setOpenModal(false)}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this record? </p>
            <p className={'warningText'}>This action cannot be undone.</p>

            <DeleteButton
              buttonType='transparent' size='lg'
              loading={deleteItem !== -1}
              onClick={async () => {
                await deleteIssue(currentRecord?.id, currentRecord?.itemIndex);
                setOpenModal(false);
              }}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => setOpenModal(false)} buttonType='transparent' size='lg'>
            Go Back
            </BackButton>

          </ModalWrapper>
        </Modal>
      </Wrapper>}
    </>
  );
});
