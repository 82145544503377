import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import {getApiUrl, getAuthHeader} from '@/api/common-utils';
import axios from 'axios';
import {OTTO_V2_API} from '@/api/otto-v2';
import StringField from './formFields/StringField';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PopupField from './formFields/PopupFields';
import ReviewQAField from './formFields/ReviewQAField';
import OpenHoursField from './formFields/OpenHoursField';
import MediaField from './formFields/MediaField';
import AttributeField from './formFields/AttributeField';
import {faArrowUpRightFromSquare, faBarsStaggered, faClock, faComments, faPhone, faSquareList, faStar, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faCalendar, faList, faLocationDot, faEarthAmericas, faIdBadge, faImage} from '@fortawesome/pro-light-svg-icons';
import {faTwitter, faFacebookF, faInstagram, faYoutube, faTiktok, faLinkedinIn, faPinterest} from '@fortawesome/free-brands-svg-icons';
import {useStore} from '@/store/root-store';
import {Tooltip} from 'antd';
import {notification} from '@/utils/notification-v2';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {BaseApi} from '@/api/base-api';
import {Preview} from './preview';
import {ModalForms} from './ModalForms';
import {humanize} from '@/utils/string';
import FreezeWrapper from '../../freezTooltip';
import {Badge, DeployContainer, Header, ModalStyled, NoDataDiv, StyledContainer, StyledDivider, StyledSwitch, TooltipContent} from './styles';
import {faLock, faLockOpen, faShieldCheck, faTriangleExclamation} from '@fortawesome/pro-solid-svg-icons';
import {DeployColumn} from './formFields/deployColumn';
import {isArray} from 'lodash';
import {getSingleUrlParam} from '@/utils/url';

export const GbpOptimization = observer(() => {
  const [attributes, setAttributes] = useState<any>();
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState<any>();
  const [reviews, setReviews] = useState<any>();
  const [selectedField, setSelectedField] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState<any>(null);
  const [suggestions, setSuggestions] = useState(0);
  const [loader, setLoader] = useState(false);
  const [key, setKey] = useState(Math.random().toFixed(3));
  const [services, setServices] = useState([]);
  const [isLockOn, setIsLockOn] = useState(false);
  const [optimizationData, setOptimizationData] = useState<any>([]);
  const [modalSuggestion, setModalSuggestion] = useState<any>();
  const [selectedValue, setSelectedValue] = useState<any>();
  const socialMediaAttributes = ['attributes/url_instagram', 'attributes/url_youtube', 'attributes/url_linkedin', 'attributes/url_facebook', 'attributes/url_appointment', 'attributes/url_tiktok', 'attributes/url_twitter', 'attributes/url_pinterest'];
  const [modalLoader, setModalLoader] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<any>({});
  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [tooltipLoader, setTooltipLoader] = useState(false);
  const [unviewedIncidents, setUnviewedIncidents] = useState(0);
  const [incidents, setIncidents] = useState([]);
  const [repollingInProgress, setRepollingInProgress] = useState(false);
  const [fieldsList, setFieldsList] = useState([
    {
      label: 'Reviews',
      fieldName: 'reviews',
      icon: faStar,
      customFieldType: 'reviews',
    },
    {
      label: 'Q&A',
      fieldName: 'q_a',
      icon: faComments,
      customFieldType: 'q_a',
    },
    {
      label: 'Images',
      fieldName: 'images',
      icon: faImage,
      fieldType: 'MEDIA',
      customFieldType: 'media',
    },
    {
      label: 'Name',
      fieldName: 'business_name',
      icon: faIdBadge,
      fieldType: 'FIELD',
      customFieldType: 'string',
    },
    {
      label: 'Description',
      fieldName: 'business_description',
      icon: faIdBadge,
      fieldType: 'FIELD',
      customFieldType: 'string',
    },
    {
      label: 'Category',
      fieldName: 'categories',
      icon: faList,
      fieldType: 'FIELD',
      customFieldType: 'popup',
    },
    {
      label: 'Services',
      fieldName: 'services',
      icon: faSquareList,
      fieldType: 'FIELD',
      customFieldType: 'popup',
    },
    {
      label: 'Attributes',
      fieldName: 'attributes',
      icon: faBarsStaggered,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'attributes',
    },
    {
      label: 'Address',
      fieldName: 'address_lines',
      icon: faLocationDot,
      fieldType: 'FIELD',
      customFieldType: 'popup',
    },
    {
      label: 'Open hours',
      fieldName: 'open_hours',
      icon: faClock,
      fieldType: 'FIELD',
      customFieldType: 'open_hours',
    },
    {
      label: 'Booking link',
      fieldName: 'attributes/url_appointment',
      icon: faCalendar,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
    },
    {
      label: 'Website URL',
      fieldName: 'website_uri',
      icon: faEarthAmericas,
      fieldType: 'FIELD',
      customFieldType: 'string',
    },
    {
      label: 'Phone number',
      fieldName: 'phone_numbers',
      icon: faPhone,
      fieldType: 'FIELD',
      customFieldType: 'popup',
    },
    {
      label: 'Instagram URL',
      fieldName: 'attributes/url_instagram',
      icon: faInstagram,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
    },
    {
      label: 'YouTube URL',
      fieldName: 'attributes/url_youtube',
      icon: faYoutube,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
    },
    {
      label: 'LinkedIn URL',
      fieldName: 'attributes/url_linkedin',
      icon: faLinkedinIn,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
    },
    {
      label: 'Facebook URL',
      fieldName: 'attributes/url_facebook',
      icon: faFacebookF,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
    },
    {
      label: 'Twitter URL',
      fieldName: 'attributes/url_twitter',
      icon: faTwitter,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
    },
    {
      label: 'TikTok URL',
      fieldName: 'attributes/url_tiktok',
      icon: faTiktok,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
    },
    {
      label: 'Pinterest URL',
      fieldName: 'attributes/url_pinterest',
      icon: faPinterest,
      fieldType: 'ATTRIBUTE',
      customFieldType: 'string',
    },
  ]);

  const {ottoV2Store: {
    selectedLocation,
  }, settings: {customer: {profile: {whitelabelOtto}}},
  gsc: {googleAnalytics: {getGMBData}},
  } = useStore('');

  useEffect(() => {
    const fetchData = async () => {
      await setLoader(true);
      try {
        if (selectedLocation) {
          await getAttributeSchema();
          await callApi();
          await getIncidentsCount();
          let data = localStorage.getItem('deployStatusTaskId');
          data = data ? JSON.parse(data) : null;
          if (data && data['locationId'] === selectedLocation?.id) {
            await getDeployStatus(data['taskId'], true);
          }
          await getServices();
        }
      } catch {
        setOptimizationData(null);
        setLoader(false);
        return Promise.reject;
      }
    };
    fetchData();
  }, [JSON.stringify(selectedLocation)]);


  useEffect(() => {
    if (isModalOpen) {
      if ((selectedField == 'categories' || selectedField == 'services') && !categories?.length) {
        getCategories();
      }
    }
  }, [isModalOpen, selectedField]);

  useEffect(() => {
    optimizationData?.length && arrangeData();
  }, [optimizationData]);

  useEffect(() => {
    const foundBusiness = findLocationById(getGMBData, selectedLocation?.locationId);
    setSelectedBusiness(foundBusiness);
  }, [getGMBData, selectedLocation?.locationId]);

  // const getSuggestions = async () => {
  //   try {
  //     await OTTO_V2_API.generateOptimizations(selectedLocation?.id);
  //   } catch (error) {
  //     notification.error('Suggestions', 'Fetching Suggestions Failed');
  //   }
  // };

  const getAttributeSchema = async () => {
    try {
      const attributesResponse = await axios.get(`${getApiUrl(BaseApi.CA_ENDPOINT, '/api')}/gbp/v1/metadata/schema/attributes?location=${selectedLocation.id}`, {
        headers: getAuthHeader(),
      });
      delete attributesResponse?.data['$schema'];
      if (!Object.keys(attributesResponse?.data?.properties).includes('attributes/url_appointment')) {
        setFieldsList(prev => prev.filter(item => item.fieldName !== 'attributes/url_appointment'));
      }
      (Object.keys(attributesResponse?.data?.properties).forEach(key => {
        if (socialMediaAttributes.includes(key)) {
          delete attributesResponse.data.properties[key];
        }
      }));
      setAttributes(attributesResponse.data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getServices = async () => {
    const servicesResponse = await OTTO_V2_API.geMetadataServices({location: selectedLocation?.id});
    setServices(servicesResponse?.services);
  };

  const getCategories = async () => {
    const categoryResponse = await OTTO_V2_API.getMetadataCategories({location: selectedLocation?.id});
    setCategories(categoryResponse?.categories);
  };

  const callApi = async () => {
    try {
      // await getSuggestions();
      await getOptimizations();
      const questionsResponse = await OTTO_V2_API.getQuestionStats(selectedLocation?.id);
      setQuestions(questionsResponse);
      const reviewsResponse = await OTTO_V2_API.getReviewStats(selectedLocation?.id);
      setReviews(reviewsResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getOptimizations = async (type = null) => {
    try {
      const optimizationResponse = await OTTO_V2_API.getOptimizations(selectedLocation?.id, {show: 'LATEST_PER_SOURCE'});
      (!repollingInProgress || type != 'first') && await checkEvents(optimizationResponse, type);
      if (type != 'repolling') {
        fetchLabels(optimizationResponse);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const checkEvents = (value, type) => {
    if (value?.optimizations?.some(item => item?.eventType == 'PUBLISHING')) {
      setTimeout(async () => {
        if (getSingleUrlParam('subGroup') == 'profile_optimizations') {
          await setRepollingInProgress(true);
          await getOptimizations('repolling');
        }
      }, 1000);
    } else {
      setRepollingInProgress(false);
      type && fetchLabels(value);
    }
  };

  const fetchLabels = async response => {
    if (response) {
      response?.optimizations?.forEach(item => {
        if (item.fieldName == 'categories') {
          item.value = item.value?.map(cat => (typeof(cat) == 'string') ? response?.included?.categories?.find(val => val.key == cat) : cat);
        } else if (item.fieldName == 'services') {
          item.value = item.value?.map(ser => {
            if (Object.keys(ser).includes('serviceTypeId')) {
              if (typeof(ser.serviceTypeId) == 'string') {
                ser.serviceTypeId = response?.included?.services?.find(val => val.key == ser.serviceTypeId);
              }
            }
            return ser;
          });
        }
      });
    }
    setOptimizationData(response?.optimizations);
  };


  const arrangeData = () => {
    const lockOnObj = optimizationData?.find(item => item.fieldName == 'lock_on');
    if (lockOnObj) {
      setIsLockOn(lockOnObj.value);
    } else {
      setIsLockOn(false);
    }
    setSuggestions(optimizationData?.filter(item => item.source == 'OTTO_AI')?.length);
    const category = optimizationData?.find(item => item.source == 'OTTO' && item.fieldName == 'categories');
    if (category) {
      setPrimaryCategory(category?.value?.[0] ?? null);
    } else {
      setPrimaryCategory(optimizationData?.find(item => item.source == 'GBP' && item.fieldName == 'categories')?.value?.[0]);
    }
    optimizationData?.length && setLoader(false);
  };

  const openModals = (fieldName, suggestions, value) => {
    if (fieldName == 'attributes') {
      const attrObj = {};
      value.forEach(item => {
        attrObj[item.fieldName] = item.value;
      });
      setSelectedAttributes(attrObj);
    }
    setSelectedField(fieldName);
    setSelectedValue(value ? JSON.parse(JSON.stringify(value)) : {});
    setModalSuggestion(suggestions);
    setIsModalOpen(true);
    setKey(Math.random().toFixed(3));
  };

  const updateData = async payload => {
    try {
      const updatePayload = [];
      payload.forEach(dataItem => {
        let value = dataItem.value;
        if (dataItem.fieldName == 'categories') {
          value = value.map(item => item.key);
        } else if (dataItem.fieldName == 'services') {
          value = value.map(item => {
            if (Object.keys(item).includes('categoryName')) {
              return {
                display_name: item.displayName,
                category_name: item.categoryName,
              };
            } else if (Object.keys(item).includes('serviceTypeId')) {
              return {service_type_id: item.serviceTypeId.key};
            }
          }).filter(item => item);
        } else if (dataItem.fieldName == 'attributes/url_appointment') {
          while (Array.isArray(value) && Array.isArray(value[0])) {
            value = value[0];
          }
          if (Array.isArray(value)) {
            value = value[0]?.startsWith('http') ? value : [`http://${value[0]}`];
          } else {
            value = value.startsWith('http') ? value : `http://${value}`;
          }
        }
        updatePayload.push({
          field_name: dataItem.fieldName,
          field_type: dataItem.fieldType,
          value: value,
        });
      });
      const data = {
        optimizations: updatePayload,
      };
      await OTTO_V2_API.updateOptimizations(selectedLocation?.id, data);
      data.optimizations.forEach(item => {
        delete item.value;
      });
      const response = await OTTO_V2_API.deployOptimizations(selectedLocation?.id, data);
      getDeployStatus(response.taskId, true);
      setLoader(true);
      // await getOptimizations('update');
      setIsModalOpen(false);

      notification.success('Update Successful', 'The field has been updated');
    } catch (error) {
      setLoader(false);
      const response = error?.response?.data?.optimizations;
      const messagesArray = response?.map(item =>{
        if (item?.error) {
          if (item.fieldName !== 'open_hours') {
            return (<span><span style={{fontWeight: 600}}>{humanize(item.fieldName?.replace('attributes/', ''))}</span>: {typeof(item.error) == 'string' ? item.error : item.error?.map(i => <div key={i}>{i}</div>)}</span>);
          } else {
            const dayError = Object.entries(item?.error)?.map(day => {
              if (isArray(day[1])) {
                return humanize(day[0])+ ': ' + (day[1] as any)?.join(',');
              } else {
                const errors = (day[1] as any)?.intervals?.map((interval, row) => {
                  if (!interval.includes('None')) {
                    return `Interval ${row + 1}: ${interval} `;
                  }
                })?.filter(item => item).join(', ');
                return humanize(day[0])+ ': ' + errors;
              }
            });
            return (<span><span style={{fontWeight: 600}}>{humanize(item.fieldName)}</span>: {dayError?.map(i => (<div key={i}>{i}</div>))}</span>);
          }
        }
      })?.filter(item => item);
      if (messagesArray?.length) {
        messagesArray.forEach(item => {
          notification.error('Update Failed', item);
        });
      }
      return null;
    } finally {
      setModalLoader(false);
    }
  };

  const getDeployStatus = async (taskId, first=false) => {
    try {
      const response = await OTTO_V2_API.taskResults(taskId);
      if (response.status == 'FAILURE') {
        notification.error('Deployment Failed', response.errorMessage);
        localStorage.setItem('deployStatusTaskId', null);
      } else if (response.status == 'SUCCESS') {
        getIncidentsCount();
        notification.success('Deployment Successful', 'Deployment was successful');
        localStorage.setItem('deployStatusTaskId', null);
      } else {
        localStorage.setItem('deployStatusTaskId', JSON.stringify({taskId: taskId, locationId: selectedLocation?.id}));
        getDeployStatus(taskId);
      }
      if (response.status == 'SUCCESS' || response.status == 'FAILURE' || (response.status == 'PENDING' && first)) {
        await setLoader(true);
        await getOptimizations(first ? 'first' : null);
      }
    } catch {
      return Promise.reject;
    }
  };

  const lockOnSwitchHandler = async value => {
    try {
      setIsLockOn(value);
      const payload = {
        optimizations: [
          {
            field_name: 'lock_on',
            value: value,
            field_type: 'SETTINGS',
          },
        ],
      };
      await OTTO_V2_API.updateOptimizations(selectedLocation?.id, payload);
      deployField({fieldName: 'lock_on', fieldType: 'SETTINGS'}, null);
      notification.success('Update Successful', `GBP Information Lock Turned ${value ? 'On' : 'Off'} successfully`);
      const updateData = optimizationData ? JSON.parse(JSON.stringify(optimizationData)) : null;
      const idx = optimizationData?.findIndex(item => item.fieldName == 'lock_on');
      if (updateData) {
        if (idx !== -1) {
          updateData[idx].value = value;
        } else {
          updateData.push({
            fieldName: 'lock_on',
            value: value,
            fieldType: 'SETTINGS',
            eventType: 'CHANGE',
            source: 'OTTO',
          });
        }
        setOptimizationData(updateData);
      }
    } catch {
      notification.error('Update Failed', 'Lock On Status Updated Failed');
    }
  };

  const generateIncidentKey = incident =>
    `${incident.createdAt}-${incident.fieldName}-${incident.value}`;

  const getIncidentsCount = () => {
    const fetchOptimizations = async () => {
      try {
        const optimizationResponse = await OTTO_V2_API.getOptimizations(selectedLocation?.id, {show: 'ALL', event_type: 'LOCK_INCIDENT,LOCK_FIXED'});
        const newIncidents = optimizationResponse?.optimizations?.map(field => {
          if (field.fieldName == 'categories') {
            field.value = field.value?.map(cat => optimizationResponse?.included?.categories?.find(val => val.key == cat));
          } else if (field.fieldName == 'services') {
            field.value = field.value?.map(ser => {
              if (Object.keys(ser).includes('serviceTypeId')) {
                ser.serviceTypeId = optimizationResponse?.included?.services?.find(val => val.key == ser.serviceTypeId);
              }
              return ser;
            });
          }
          return field;
        });
        const newIncidentKeys = newIncidents.map(generateIncidentKey);

        const viewedIncidents = JSON.parse(localStorage.getItem('viewedIncidents')) || [];

        const unviewedIncidentKeys = newIncidentKeys.filter(
          key => !viewedIncidents.includes(key),
        );

        setIncidents(newIncidents);

        if (unviewedIncidentKeys.length > 0) {
          setUnviewedIncidents(unviewedIncidentKeys.length);
        } else {
          setUnviewedIncidents(0);
        }
      } catch {
        return Promise.reject;
      }
    };
    setTimeout(() =>{
      fetchOptimizations();
    }, 5000);
  };

  const markAsViewed = () => {
    const incidentKeys = incidents.map(generateIncidentKey);
    localStorage.setItem('viewedIncidents', JSON.stringify(incidentKeys));
    setUnviewedIncidents(0);
  };

  const viewIncidents = () => {
    setSelectedField('incidents');
    setIsModalOpen(true);
    markAsViewed();
  };

  function findLocationById(data, targetLocationId) {
    if (data) {
      for (const account of data) {
        for (const businessAccount of account.businessAccounts) {
          for (const location of businessAccount.locations) {
            if (location.locationId === targetLocationId) {
              return location;
            }
          }
        }
      }
    }
    return null;
  }

  const deployField = async (field, fields=null) => {
    setTooltipLoader(true);
    try {
      const optimizations = [];
      if (field) {
        optimizations.push({
          field_name: field.fieldName,
          field_type: field.fieldType,
        });
      } else if (fields?.length) {
        fields?.forEach(item => {
          if (item?.eventType == 'CHANGE' || item?.eventType == 'PUBLISH_FAILED') {
            optimizations.push({
              field_name: item.fieldName,
              field_type: item.fieldType,
            });
          }
        });
      }
      const payload = {
        optimizations: optimizations,
      };
      const response = await OTTO_V2_API.deployOptimizations(selectedLocation?.id, payload);
      notification.info('Deployment Triggered', 'Deployment has been triggered');
      getDeployStatus(response.taskId, true);
    } catch {
      return Promise.reject;
    } finally {
      setTooltipLoader(false);
    }
  };

  return (
    <>
      {loader ? (<div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}><RingLoaderV2 width='500px' height='500px' fontSize={50} /></div> ): (
        <div style={{overflow: 'auto'}}>
          <DeployContainer>
            <span>{`${whitelabelOtto} Suggestions:`} <span style={{fontWeight: '600'}}>{suggestions}</span></span>
            <div className='buttons-container'>
              <FreezeWrapper>
                <Tooltip overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: 310}} title={
                  <TooltipContent>
                    <div className='icon-and-text'>
                      <div className='icon'>
                        <FontAwesomeIcon icon={isLockOn ? faShieldCheck : faTriangleExclamation} color={isLockOn ? '#2AC155' : '#F44343'} />
                      </div>
                      <div>
                        <p>
                          {isLockOn ?
                            'Your GBP information is securely locked, safeguarding your business from unauthorized changes. No malicious attempts can alter your details or impact your rankings.' :
                            'Unprotected, your GBP business information can be altered by others, potentially harming your reputation and rankings—without you even knowing.'}
                        </p>
                        {incidents?.length > 0 && <p onClick={viewIncidents} className='clickable-text'>{`View unauthorized attempts ${unviewedIncidents > 0 ? `(${unviewedIncidents} new)` : ''}`}</p>}
                      </div>
                    </div>
                  </TooltipContent>
                }><div className='button-container' style={{background: isLockOn ? '#18923B26' : '#F4434326'}}>
                    <StyledSwitch lockOn={isLockOn} checked={isLockOn} onChange={value => lockOnSwitchHandler(value)} checkedChildren={<FontAwesomeIcon icon={faLock} color='#18923B' />}
                      unCheckedChildren={<FontAwesomeIcon icon={faLockOpen} color='#F44343'/>}/>
                    <span>{isLockOn ? 'GBP Information Locked' : 'GBP Information Not Locked'}</span>
                    {unviewedIncidents > 0 && (
                      <Badge lockOn={isLockOn}>{unviewedIncidents}</Badge>
                    )}
                  </div></Tooltip>
              </FreezeWrapper>
            </div>
          </DeployContainer>
          <Header className='header-row'>
            <div className='deploy-cell'>STATUS</div>
            <div className='metric-cell'>METRIC</div>
            <div className='status-cell'>VALUE</div>
            <div className='preview-cell'>PREVIEW</div>
          </Header>
          {optimizationData?.length ? <StyledContainer>
            <div className='form'>
              {fieldsList.map(field => (

                <>
                  <div className='record-row' style={{alignItems: (field.label == 'Attributes' || field.customFieldType == 'popup' ) && 'unset'}}>
                    <div className='deploy-status'>
                      <DeployColumn optimizationData={optimizationData} field={field} tooltipLoader={tooltipLoader} socialMediaAttributes={socialMediaAttributes} deployField={deployField} attributes={attributes ? Object.keys(attributes?.properties) : []}/>
                    </div>
                    <div className='metrics' style={{paddingLeft: !field?.icon && 17}}>{field?.icon && <FontAwesomeIcon
                      style={{marginRight: 6}}
                      icon={field.icon}/>}
                    {field.label}
                    </div>
                    <div className='status'>
                      {field?.customFieldType == 'string' && <StringField field={field} handleSave={payload => updateData(payload)} value={optimizationData}/>}
                      {field?.customFieldType == 'popup' && <PopupField field={field} openModal={(suggestion, value) => openModals(field.fieldName, suggestion, value)} value={optimizationData}/>}
                      {field?.customFieldType == 'reviews' && <ReviewQAField
                        field={field}
                        unanswered={true}
                        isUnanswered={reviews?.notReplied}
                      />}
                      {field?.customFieldType == 'q_a' && <ReviewQAField
                        field={field}
                        unanswered={false}
                        isUnanswered={questions?.notAnswered}
                      />}
                      {field.customFieldType == 'attributes' && <AttributeField openModal={(suggestion, value) => openModals(field.fieldName, suggestion, value)} value={optimizationData} attributes={attributes ? Object.keys(attributes?.properties) : []}/>}
                      {field.customFieldType == 'media' && <MediaField openModal={(suggestion, value) => openModals(field.fieldName, suggestion, value)} value={optimizationData}/>}
                      {field.customFieldType == 'open_hours' && <OpenHoursField field={field} openModal={(suggestion, value) => openModals(field.fieldName, suggestion, value)} value={optimizationData}/>}
                    </div>
                  </div>
                  <StyledDivider />
                </>
              ))}
            </div>
            <Preview
              optimizationData={optimizationData}
            />
          </StyledContainer> : optimizationData == null && <NoDataDiv>No Data Available</NoDataDiv>}
          <ModalStyled
            key={key}
            width={selectedField == 'categories' ? 560 : selectedField == 'images' ? 1280 : selectedField == 'incidents' ? 1280 : 800}
            closable={true}
            closeIcon={faXmark && <FontAwesomeIcon icon={faXmark} />}
            mask={true}
            title={selectedField == 'categories' ? 'Business Category' : selectedField == 'incidents' ? <div>Change attempts<br/><a target='_blank'
              rel='noopener noreferrer' href={selectedBusiness?.googlemapsUrl} style={{color: '#2D6CCA', fontWeight: 400, fontSize: 14}}>{selectedLocation?.businessName}<FontAwesomeIcon style={{marginLeft: '6px'}} icon={faArrowUpRightFromSquare} /></a></div> :
              humanize(selectedField)} visible={isModalOpen} footer={false} onClose={() => setIsModalOpen(false)} style={{height: 100}}>
            {isModalOpen && <ModalForms
              updateData={payload => updateData(payload)}
              attributes={attributes}
              selectedAttributes={selectedAttributes}
              services={services}
              selectedField={selectedField}
              categories={categories}
              setIsModalOpen={setIsModalOpen}
              modalSuggestion={modalSuggestion}
              selectedValue={selectedValue}
              setModalLoader={setModalLoader}
              modalLoader={modalLoader}
              incidents={incidents}
              primaryCategory={primaryCategory}
              setCategories={setCategories}
              setServices={setServices}
              selectedLocation={selectedLocation?.id}
            />}
          </ModalStyled>
        </div>

      )}
    </>
  );
});
