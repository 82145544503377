import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faStore} from '@fortawesome/pro-solid-svg-icons';
import {Dropdown, Input, Menu, Spin, Tooltip} from 'antd';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react-lite';
import {LoadingOutlined, MoreOutlined} from '@ant-design/icons';
import {faMagnifyingGlass} from '@fortawesome/pro-light-svg-icons';
import {Button as ViewBtton, Table as AntdTable, Modal, SkeletonHorizontalLoaderGray} from '@/components/common-components/components';
import {addProtocolToDomain} from '@/utils/url';
import {urlDomainRegex} from '@/constants/regex';
import styles from './styles.module.scss';
import {Button} from '@/components/common-components/v2/Button';
import {faBook, faCircleInfo, faTrash} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import {debounce} from 'lodash';
import {openUrl} from '@/utils/router';
import {SettingsModal} from './Component/settings-modal';
import {ImportBusinessModal} from '../local-seo-scan/seo-projects-list-v2/importBusinessModal';
import {NewQuotaDropDown} from '@/components/common-components/components/new-quote-dropdown';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {PaginationStyled} from '../otto-page-detail/style';
import {TopupModal} from '../otto-page-detail/Modals/topupModal';
import {planApi} from '@/api/account';
import {getHyperDriveTooltip} from '../otto-page-detail/Component/contentDetailV2';
// import WidgetModalAlpha from './add-stite-modal-v2';
// import {Pill} from '@/components/layoutV2/user-menu/styles';
import {DeleteConfirmModal} from '../../gsc/gsc/PageGroupings/blocks/Groups/DeleteConfirm';
import WidgetModal from './add-site-modal';
import {convertMinutesIntoReadAbleTime} from '@/utils/moment';
import {VideoWrapper} from '@/components/common-components/components/videos-wrapper';

export const getPixelStateAsBoolean = pixelState => {
  switch (pixelState) {
    case 'installed':
      return true;
    default:
      return false;
  }
};

export const getPixelErrorMessage = pixelState => {
  switch (pixelState) {
    case 'wrong_uuid':
      return 'Wrong UUID';
    case 'not_installed':
      return 'Not installed';
    default:
      return 'Not installed';
  }
};

interface Props {
  isCompleted?: boolean;
}
export const OttoV2Main:React.FC<Props> = observer(() => {
  const {
    ottoStore: {
      setDomainLoading,
    },
    ottoV2Store: {
      loadOttoV2Projects,
      getOttoV2ProjectsList,
      loadingProjects,
      deleteOttoProject,
      setProjectListShouldRepoll,
      loadOttoV2Project,
      setDefaultParams,
      defaultParams,
      getOttoV2Project,
      resetOttoV2Project,
      quotaAllocations,
      getOttoQuotaAllocations,
      setOttoIdForGscSettings,
    },
    siteAuditorV2: {
      loadAuditedSitesList,
      params,
      setParams,
      updateSelectedSiteSA,
      setGscOpenModalValue,
      setStopLoadProjectApi,
    },
    settings: {customer: {getCustomerQuota, profile: {whitelabelOtto, quotaUtilization, isWhitelabel, nextPaymentAt, isParentAccount, addons, setTopupPrices, isLinkgraph}}},
    gsc: {googleAnalytics: {setGaDataModal, gaDataModal, getGMBData, getGMBSetting}, criteria},
  } = useStore('');

  const {reportBuilder: {details: {
    loadReportBuilderGSCList,
  }}} = useStore('');
  const [gbpModalVisible, setGbpModalVisible] = useState(false);
  const [shouldOpenGBP, setShouldOpenGBP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletePageModalVisible, setIsDeletePageModalVisible] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [connectGMBLoader, setConnectGMBLoader] = useState(-1);
  const router = useRouter();
  const [sitesList, setSitesList] = useState([]);
  const [isWidgetModalVisible, setIsWidgetModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [uuid, setUuid] = useState('');

  const [isInstructionModalVisible, setIsInstructionModalVisible] = useState(false);
  const [selectedConnectingProject, setSelectedConnectingProject] = useState(null);
  const [connectGSCLoader, setConnectGSCLoader] = useState(-1);
  const [hyperDriveRecurring, setHyperDriveRecurring] = useState<'yes' | 'no'>('no');
  const [instructionsData, setInstructionsData] = useState<{
    domainToOpen: string;
    uuid?: string;
  }>(null);
  const [topUpBulkVisibility, setTopUpBulkVisibility] = useState({
    visibility: false,
    price: 0,
    title: '',
    options: [],
    consumed: 0,
    total: 0,
    recurring: '',
    quotaKey: '',
    desc: '',
    buttonTxt: '',
    overAllQuota: 0,
    isAiGeneration: false,
  });

  const getOttoPlans = async () => {
    const response = await planApi.getPlans({is_addon: 'True'});
    if (response?.isCancel) {
      return false;
    }
    const activeAddon = addons?.length > 0 && addons[addons?.length - 1];
    const selectedPlan = response?.find(plan => plan?.id == activeAddon?.planId);
    if (selectedPlan) {
      setTopupPrices(
        {
          topupHyperdriveCreditCost: selectedPlan?.topupHyperdriveCreditCost,
          topupOttoAiQuotaCost: selectedPlan?.topupOttoAiQuotaCost,
          topupOttoPageCost: selectedPlan?.topupOttoPageCost,
          topupOttoProjectCost: selectedPlan?.topupOttoProjectCost,
        },
      );
    }
  };

  useEffect(() => {
    if (addons?.length) getOttoPlans();
  }, [addons?.length]);

  const getTopupQuotaConf = key => {
    return quotaAllocations?.find(i => i?.quotaKey === key);
  };

  // useEffect(()=> {
  //   debugger
  //   country && loadReportBuilderGSCList(country);
  // }, [country]);
  const getSummedHyperDriveQuotaValues = key => {
    return (quotaUtilization?.ca?.hyperdriveCredits[key] ?? 0) + (quotaUtilization?.ca?.hyperdriveCreditsPurchased[key] ?? 0);
  };

  const quotaArray = [
    {
      quotaTitle: `${whitelabelOtto} Sites`,
      available: quotaUtilization?.ca?.allowedOttoV2Projects?.total - quotaUtilization?.ca?.allowedOttoV2Projects?.consumed,
      used: quotaUtilization?.ca?.allowedOttoV2Projects?.consumed,
      total: quotaUtilization?.ca?.allowedOttoV2Projects?.total,
      topupConf: {
        price: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.pricePerUnit || 0,
        title: `${whitelabelOtto} Sites`,
        options: isLinkgraph ? ['price', 'AI_generation', 'hyperdrive'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.topOptions[0],
        quotaKey: getTopupQuotaConf('quota_ca_allowed_otto_v2_projects')?.quotaKey,
        desc: 'per site per month',
        nextPaymentAt,
        buttonTxt: `${whitelabelOtto} site`,
        showSlider: true,
        siteDesc: 'Get more from OTTO Sites and enjoy bigger discounts with each additional site quota you secure.',
      },
    },
    {
      quotaTitle: `${whitelabelOtto} HyperDrive`,
      available: getSummedHyperDriveQuotaValues('total') - getSummedHyperDriveQuotaValues('consumed'),
      used: getSummedHyperDriveQuotaValues('consumed'),
      total: getSummedHyperDriveQuotaValues('total'),
      quotaInfoTooltip: getHyperDriveTooltip(
        [
          {used: quotaUtilization?.ca?.hyperdriveCredits?.consumed, total: quotaUtilization?.ca?.hyperdriveCredits?.total, label: 'Recurring'},
          {used: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.consumed, total: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.total, label: 'Non-Recurring'},
        ],
      ),
      topupConf: {
        price: hyperDriveRecurring === 'yes' ? (getTopupQuotaConf('quota_ca_hyperdrive_credits')?.pricePerUnit || 0) * 120 : 1,
        title: `${whitelabelOtto} HyperDrive`,
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: hyperDriveRecurring === 'yes' ? getTopupQuotaConf('quota_ca_hyperdrive_credits')?.topOptions[0] : 'purchased',
        quotaKey: getTopupQuotaConf('quota_ca_hyperdrive_credits')?.quotaKey,
        desc: hyperDriveRecurring === 'yes' ? 'per 120 credits per month' : 'per 1 credit',
        nextPaymentAt,
        buttonTxt: 'site',
        step: hyperDriveRecurring === 'yes' ? 120 : 1,
        multiplesOfStep: hyperDriveRecurring === 'yes' ? true : false,
        unitIsMultipleOfStep: hyperDriveRecurring === 'yes' ? true : false,
        recurringIsOptional: true,
        recurringValue: hyperDriveRecurring,
        setRecurringValue: setHyperDriveRecurring,
        wholeCredits: true,
      },
    },
    {
      quotaTitle: 'Full Article Generation Quota',
      available: quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total - quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed,
      used: quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.consumed),
      total: (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.total || 0)) - quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.consumed + + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.consumed),
      topupConf: {
        price: 1,
        title: 'Full Article Generation Quota',
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: '',
        quotaKey: 'allowed_ai_premium_content_generation',
        desc: 'per article',
        buttonTxt: 'point',
        overAllQuota: (quotaUtilization?.ca?.allowedAiPremiumContentGeneration?.total + Number(quotaUtilization?.ca?.purchasedPremiumAiQuota?.total)),
      },
      purchasedQuota: quotaUtilization?.ca?.purchasedPremiumAiQuota?.total,
    },
    {
      quotaTitle: 'AI Generation',
      available: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total - quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed,
      used: quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed,
      total: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
      topupConf: {
        price: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.pricePerUnit * 500 || 0,
        title: 'AI Generation Quota',
        options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
        recurring: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.topOptions[0],
        quotaKey: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.quotaKey,
        desc: 'per 500 points',
        buttonTxt: 'AI Generation',
        overAllQuota: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
        nextPaymentAt,
        step: 500,
        multiplesOfStep: true,
        unitIsMultipleOfStep: true,
        wholeCredits: true,
      },
    },
  ];
  useEffect(() => {
    if (topUpBulkVisibility?.visibility) {
      const item: any = quotaArray?.find(item => item?.quotaTitle === 'OTTO HyperDrive');
      setTopUpBulkVisibility({
        ...item?.topupConf,
        visibility: true,
        consumed: item?.used,
        total: item?.total,
      });
    }
  }, [hyperDriveRecurring]);
  const {width} = useWindowSize();

  useEffect(() => {
    const now = new Date().getTime();
    loadAuditedSitesList(false, false, now);
    getOttoQuotaAllocations();
  }, []);


  const getDivColor = (value: number) => {
    if (value <= 30 || !value) {
      return 'rgba(244,67,67,0.07)';
    } else if (value >= 31 && value <= 80) {
      return 'rgba(241,170,62,10%)';
    } else {
      return 'rgba(42,193,85,0.07)';
    }
  };

  const getDotColor = (value: number) => {
    if (value <= 30 || !value) {
      return '#F44343';
    } else if (value >= 31 && value <= 80) {
      return '#F1AA3E';
    } else {
      return '#2ac155';
    }
  };

  useEffect(() => {
    if (getOttoV2Project === null && selectedConnectingProject !== null && !selectedConnectingProject?.is_gbp) {
      loadOttoV2ProjectFromApi(selectedConnectingProject);
    } else if (getOttoV2Project && getOttoV2Project?.uuid === selectedConnectingProject?.uuid && !selectedConnectingProject?.is_gbp) {
      setConnectGSCLoader(-1);
      const connectedData = getOttoV2Project?.connectedData;
      loadReportBuilderGSCList(connectedData?.gscDetails?.countryCode || '');
      criteria.updateCriteria({
        ...criteria,
        property: connectedData?.gscDetails?.propertyUrl || '',
      });
      setGscOpenModalValue(true, 'GSC Setting', true, selectedConnectingProject?.siteAudit, false);
      setStopLoadProjectApi(true);
      setOttoIdForGscSettings(selectedConnectingProject?.uuid || '');
      setGaDataModal({
        ...gaDataModal,
        visible: true,
        siteUrl: connectedData?.gscDetails?.propertyUrl || '',
        gaConnected: getOttoV2Project?.connectedData?.isGscConnected,
        settingId: null,
        settingsEnabled: false,
        hasSiteGaCredentials: null,
        saProjectId: selectedConnectingProject?.siteAudit,
        countryCode: connectedData?.gscDetails?.countryCode || '',
      });
    }

    if (getOttoV2Project === null && selectedConnectingProject !== null && selectedConnectingProject?.is_gbp) {
      loadOttoV2ProjectFromApi(selectedConnectingProject);
    } else if (getOttoV2Project && getOttoV2Project?.uuid === selectedConnectingProject?.uuid && selectedConnectingProject?.is_gbp) {
      if (shouldOpenGBP && getGMBData?.length) {
        setConnectGMBLoader(-1);
        setShouldOpenGBP(false);
        setGbpModalVisible(true);
      }
    }
  }, [getOttoV2Project, selectedConnectingProject, getGMBData]);

  const loadingArray = [null, 'PENDING', 'STARTED'];

  const getSeoTask = () => {
    setParams({
      ...params,
      page: 1,
      search: '',
    }, true, true);
    loadAuditedSitesList();
    setIsWidgetModalVisible(true);
  };
  const openOnStepTwo = (projectUuid: string) => {
    setUuid(projectUuid);
    setActiveTab(1);
    setIsWidgetModalVisible(true);
  };
  const deleteProject = async id => {
    await deleteOttoProject(id);
    getCustomerQuota();
  };

  const addDefaultSrc = e => {
    e.target.src = '/img/project-no-screenshot.png';
  };
  const isValidDomain = domain => {
    return domain?.match(urlDomainRegex);
  };
  const findValidDomain = data => {
    if (typeof data === 'object' && data !== null) {
      // Check if the object has a "domain" property and if it's a valid domain
      if (Object.prototype.hasOwnProperty.call(data, 'domain') && isValidDomain(data.domain)) {
        return addProtocolToDomain(data.domain); // Found a valid domain, return it
      } else {
        // Traverse the object recursively
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const result = findValidDomain(data[key]);
            if (result !== null) {
              return addProtocolToDomain(result); // Stop and return the domain if found
            }
          }
        }
      }
    } else if (typeof data === 'string' && isValidDomain(data)) {
      // If data is a string and a valid domain, return it
      return data;
    }
    return null; // No valid domain found in this object
  };


  const handleSeeTask = items => {
    setDomainLoading();
    updateSelectedSiteSA(items?.siteAudit);
    // router?.push(`/otto-page-v2${router?.pathname?.includes('public') ? '-public' : ''}/tasks?domain=${items?.domain}`);
    router?.push(`/otto-page-v2/tasks?uuid=${items?.uuid}`);
  };

  const instructionHandler = record => {
    if (record?.hostname) {
      setInstructionsData({domainToOpen: record.hostname, uuid: record?.uuid});
      setIsInstructionModalVisible(true);
    }
  };


  const tableColumn = [
    {
      title: <Title>WEBSITE</Title>,
      dataIndex: 'domain',
      key: 'domain',
      sortFieldName: 'domain',
      width: 170,
      align: 'left' as 'left',
      fixed: width > 800 ? 'left' as 'left' : false,
      render: (_, record) => {
        const url = `https://www.google.com/s2/favicons?sz=64&domain_url=${addProtocolToDomain(record?.hostname)}`;
        return <div onClick={() => handleSeeTask(record)}>
          <FlexWrapper style={{cursor: 'pointer'}}>
            <Img onError={e => addDefaultSrc(e)} src={isValidDomain(record?.hostname) ? url : `https://www.google.com/s2/favicons?sz=64&domain_url=${findValidDomain(record?.hostname)?.includes('.com') ? findValidDomain(record?.hostname) : `${findValidDomain(record?.hostname)}.com`}`} alt={record?.hostname}/>
            <div style={{display: 'flex', gap: 15, width: '93%'}}>
              <Tooltip title={record?.hostname || '-'} overlayInnerStyle={{whiteSpace: 'nowrap', width: 'fit-content', background: '#000'}}>
                <BoldText>{record?.hostname || '-'}</BoldText>
              </Tooltip>
              {
                record?.taskStatus === 'FAILURE' && <FailedBanner>Failed</FailedBanner>
              }
              {/* <GrayText>{record?.location} ‧  {titleCase(record?.dateRange)}</GrayText> */}
            </div>
          </FlexWrapper>
        </div>;
      },
    },
    {
      title: <Title>{`${whitelabelOtto} PIXEL`}</Title>,
      dataIndex: 'isWidgetEnabled',
      key: 'isWidgetEnabled',
      width: 115,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 10 : 1,
      }),
      render: (_, record) => {
        return (
          !record?.readyForProcessing ? (
            <WidgetDiv
              width={'446px'}
              backgroundColor={'rgba(244, 67, 67, 0.07)'}
            >
              <FontAwesomeIcon icon={faCircle} fontSize={8} color='#F44343' />
              Installation process was not completed.
              <RetryBtn onClick={() => openOnStepTwo(record?.uuid)}>Continue installation.</RetryBtn>
            </WidgetDiv>
          ) : (
            <>
              {loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '100px' }/> :
                <WidgetDiv
                  width={getPixelStateAsBoolean(record?.pixelTagState) ? '80px' : '160px'}
                  backgroundColor={getPixelStateAsBoolean(record?.pixelTagState) ? 'rgba(42, 193, 85, 0.07)' : 'rgba(244, 67, 67, 0.07)'}
                >{getPixelStateAsBoolean(record?.pixelTagState) ?
                    <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#2AC155' />Installed</> :
                    <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#F44343' />{getPixelErrorMessage(record?.pixelTagState)} <RetryBtn onClick={() => instructionHandler(record)}>Retry</RetryBtn></>
                  }
                </WidgetDiv>}
            </>
          )
        );
      },
    },
    {
      title: <Title>{`${whitelabelOtto} SEO`}</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 80,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '70px' }/> :
                <>
                  {
                    <WidgetDiv
                      width={record?.isEngaged ? '80px' : '110px'}
                      backgroundColor={record?.isFrozen ? 'rgba(45, 108, 202, 0.07)' : record?.isEngaged ? 'rgba(42, 193, 85, 0.07)' : 'rgba(241, 170, 62, 10%)'}
                    >{record?.isFrozen ? (
                        <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#2D6CCA' />Frozen</>
                      ) : record?.isEngaged ? (
                        <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#2AC155' />Engaged</>
                      ) : (
                        <><FontAwesomeIcon icon={faCircle} fontSize={8} color='#F1AA3E' />Not Engaged</>
                      )
                      }</WidgetDiv>
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title>{`${whitelabelOtto} SCORE`}</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 50,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '50px' }/> :
                <>
                  {
                    <WidgetDiv
                      width={'60px'}
                      backgroundColor={getDivColor(record?.afterSummary?.seoOptimizationScore)}
                    >
                      <>
                        <FontAwesomeIcon icon={faCircle} fontSize={8} color={getDotColor(record?.afterSummary?.seoOptimizationScore)} />{record?.afterSummary?.seoOptimizationScore}
                      </>
                    </WidgetDiv>
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title>FIXED ISSUES</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 40,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '50px' }/> :
                <>
                  {
                    [null, undefined, 'null', 'undefined'].includes(record?.afterSummary?.deployedFixes) ? (<TextDiv style={{marginLeft: '22px'}}>-</TextDiv>) : (<TextDiv style={{}}>{record?.afterSummary?.deployedFixes}</TextDiv>)
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title>HEALTHY PAGES</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 50,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '50px' }/> :
                <>
                  {
                    [null, undefined, 'null', 'undefined'].includes(record?.afterSummary?.healthyPages) && [null, undefined, 'null', 'undefined'].includes(record?.afterSummary?.totalPages) ?
                      (<TextDiv style={{marginLeft: '22px'}}>-</TextDiv>) : (<TextDiv style={{}}>{`${record?.afterSummary?.healthyPages}/${record?.afterSummary?.totalPages}`}</TextDiv>)
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title>TIME SAVED</Title>,
      dataIndex: 'isEngaged',
      key: 'isEngaged',
      width: 60,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '50px' }/> :
                <>
                  {
                    [null, undefined, 'null', 'undefined'].includes(record?.timeSavedTotal) ? (
                      <TextDiv style={{marginLeft: '22px'}}>-</TextDiv>
                    ) : (
                      <Tooltip title={convertMinutesIntoReadAbleTime(record?.timeSavedTotal)} overlayInnerStyle={{whiteSpace: 'nowrap', width: 'fit-content', background: '#000'}}><SaveTimeText>{convertMinutesIntoReadAbleTime(record?.timeSavedTotal)}</SaveTimeText></Tooltip>
                    )
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title>PAGES WITH <br/>ISSUES</Title>,
      dataIndex: 'pagesWithIssues',
      key: 'pagesWithIssues',
      width: 60,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '70px' }/> :
                <>
                  {
                    [null, undefined, 'null', 'undefined'].includes(record?.pagesWithIssues) ? (<TextDiv style={{marginLeft: '22px'}}>-</TextDiv>) : (<TextDiv style={{marginLeft: '22px'}}>{record?.pagesWithIssues}</TextDiv>)
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title>LAST ANALYSIS</Title>,
      dataIndex: 'lastCrawl',
      key: 'lastCrawl',
      width: 80,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '90px' }/> :
                <>
                  {
                    <Tooltip title={record?.lastCrawl ? moment(record?.lastCrawl)?.format('MMM DD, YYYY') : '-'} overlayInnerStyle={{whiteSpace: 'nowrap', width: 'fit-content', background: '#000'}}><SaveTimeText>{record?.lastCrawl ? moment(record?.lastCrawl)?.format('MMM DD, YYYY') : '-'}</SaveTimeText></Tooltip>
                  }
                </>
            }
          </>
        );
      },
    },
    {
      title: <Title>CONNECTED <br/>DATA</Title>,
      dataIndex: 'reportsData',
      key: 'reportsData',
      width: 60,
      align: 'left' as 'left',
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record, idx) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <SkeletonHorizontalLoaderGray lightLoader skeletonHeight='10px' height = { '25px' } width = { '70px' }/> :
                <FlexWrapper>
                  {<>
                    <Tooltip title={record?.connectedData?.isGscConnected ?
                      (`GSC Connected`) :
                      'GSC not connected'}>
                      <StyledIConWrapper style={{backgroundColor: record?.connectedData?.isGscConnected ? '#144198' : '#A3A4A466', cursor: 'pointer'}} onClick={() => {
                        setConnectGSCLoader(idx);
                        resetOttoV2Project(null);
                        setSelectedConnectingProject({...record, is_gbp: false});
                      }}>
                        {connectGSCLoader === idx ? <Spin indicator={<LoadingOutlined style={{fontSize: 12, display: 'flex', alignItems: 'center'}} spin />} /> : <img src='/img/icon/GSC_Icon.png' width={20} style={{opacity: record?.connectedData?.isGscConnected ? '' : '50%'}}/>}
                      </StyledIConWrapper>
                    </Tooltip>
                    <Tooltip title={record?.connectedData?.isGbpConnected ?
                      (`GBP Connected`) :
                      'GBP not connected'}>
                      <StyledIConWrapper style={{backgroundColor: record?.connectedData?.isGbpConnected ? '#2D6CCA' : '#A3A4A466', cursor: 'pointer'}} onClick={() => {
                        setConnectGMBLoader(idx);
                        resetOttoV2Project(null);
                        setShouldOpenGBP(true);
                        setSelectedConnectingProject({...record, is_gbp: true});
                      }}>
                        {connectGMBLoader === idx ? <Spin indicator={<LoadingOutlined style={{fontSize: 12, display: 'flex', alignItems: 'center'}} spin />} /> : <FontAwesomeIcon icon={faStore} color={'#fff'} fontSize='16px' style={{opacity: record?.connectedData?.isGbpConnected ? '' : '50%'}}/>}
                      </StyledIConWrapper>
                    </Tooltip>
                  </>}
                </FlexWrapper>
            }
          </>
        );
      },
    },
    {
      title: (''),
      dataIndex: 'searchedOn',
      key: 'searchedOn',
      align: 'right' as 'right',
      width: 45,
      onCell: record => ({
        colSpan: !record?.readyForProcessing ? 0 : 1,
      }),
      render: (_, record) => {
        return (
          <>
            {
              loadingArray.includes(record?.taskStatus) ?
                <></> :
                <div style={{position: 'relative'}}>
                  <ButtonStyled
                    buttonType='transparent'
                    onClick={() => handleSeeTask(record)}
                  >
                      View
                  </ButtonStyled>
                </div>
            }
          </>);
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 20,
      align: 'left' as 'left',
      render: (_, record)=> {
        return <div className={styles.dropdownStyles}>
          {!record?.readyForProcessing ? (
            <FontAwesomeIcon icon={faTrash} color='#4E5156' fontSize={16} onClick={() => {
              setSelectedDeleteId(record?.uuid); setIsDeletePageModalVisible(true);
            }} style={{cursor: 'pointer'}}/>
          ) :
            (<Dropdown trigger={['click']} overlay={
              <Menu>
                <Menu.Item key={'3'} onClick={() => {
                  setSelectedDeleteId(record?.uuid); setIsDeletePageModalVisible(true);
                }}>{'Remove'}</Menu.Item>
                {
                  record?.siteAudit && <Menu.Item key={'3'} onClick={() => router.push(`/site-audit/overview/?id=${record?.siteAudit}`)}>{'Open Site Audit'}</Menu.Item>
                }
              </Menu>} placement='bottomLeft'
            ><span style={{fontSize: '16px', cursor: 'pointer'}} onClick={e => {
                e.stopPropagation();
              }}><span className={styles.removeStyle}>
                  <MoreOutlined />
                </span>
              </span>
            </Dropdown>)}
        </div>;
      },
    },
  ];

  useEffect(() => {
    setDefaultParams({...defaultParams, pageSize: 20, page: 1});
    getCustomerQuota();
    return () => setDefaultParams({...defaultParams, pageSize: 20, page: 1, search: ''}, true);
  }, []);

  useEffect(() => {
    setProjectListShouldRepoll(true);
    return () => {
      setProjectListShouldRepoll(false);
    };
  }, []);

  useEffect(() => {
    if (!loadingProjects && getOttoV2ProjectsList?.results?.length) {
      setSitesList(getOttoV2ProjectsList?.results);
    } else if (!getOttoV2ProjectsList?.results?.length) {
      setSitesList([]);
    }
  }, [JSON.stringify(getOttoV2ProjectsList?.results)]);

  const tableFilterHandler = debounce(async e => {
    if (e?.target?.value) {
      const passedValue = e.target.value;
      setDefaultParams({...defaultParams, pageSize: 20, page: 1, search: passedValue.toLowerCase()}, false, true);
    } else {
      setDefaultParams({search: '', pageSize: 20, page: 1});
    }
  }, 500);

  const loadOttoV2ProjectFromApi = async record => {
    await loadOttoV2Project(record?.uuid);
    if (selectedConnectingProject?.is_gbp) {
      await getGMBSetting();
    }
  };
  const knowledgeBaseUrl = 'https://searchatlasgroup.notion.site/OTTO-SEO-8ff6e1d26d9844c69e5b14ddb46f5cc9';

  return (<>
    <HeaderWrapper>
      <div style={{display: 'flex', justifyContent: 'space-between', width: '1200px', margin: '0px auto', borderRadius: '8px'}}>
        <HeadingContainer isWhitelabel={isWhitelabel}>
          {router?.pathname?.includes('public') ? <div style={{color: '#A3A4A4'}}>Quota: 2 projects</div> :
            <NewQuotaDropDown quotaTableArray={quotaArray} setTopUpBulkVisibility={setTopUpBulkVisibility} />}
          {/* <QuotaInfoDetail isCenter consumedData={quotaUtilization?.ca?.allowedOttoV2Projects?.consumed} totalData= {quotaUtilization?.ca?.allowedOttoV2Projects?.total} heading={'Quota'} description={'Total number of OTTO projects'}/> */}
          <FlexWrapperHeading>
            <HeadingWrapper>
              <Heading>{`${whitelabelOtto} SEO Automation`}</Heading>
            </HeadingWrapper>
            <Description>{`Revolutionize your website maintenance with ${whitelabelOtto}'s cutting-edge technology. Seamlessly detect and resolve issues across your site, guaranteeing smooth functionality and enhancing user satisfaction.`}</Description>
            <FlexWrapper>
              <Button style={{height: '43px', margin: '0px'}} color='blue' onClick={() => getSeoTask()}>+ Create</Button>
            </FlexWrapper>
          </FlexWrapperHeading>
        </HeadingContainer>
        <div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '24px'}}>
            {isWhitelabel && !isParentAccount ? null : <WhitelistCrawler onClick={() => openUrl('https://searchatlas.com/blog/whitelist-monitoring-on-cloudflare/', '_blank')}>
              <FontAwesomeIcon icon={faCircleInfo} color='#E8E8E8' fontSize={12} />
            How to Whitelist our Crawler
            </WhitelistCrawler>}
            <div
              onClick={()=>openUrl(knowledgeBaseUrl, '_blank')}
              style={{color: '#34AEF3', display: 'flex', alignItems: 'center', gap: '8px', lineHeight: '20px', backgroundColor: 'transparent', cursor: 'pointer'}}>
              <><FontAwesomeIcon icon={faBook} color={'#34AEF3'} /></>Knowledge Base</div>
          </div>
          {!isWhitelabel && <VideoWrapper/>}
        </div>
      </div>
    </HeaderWrapper>
    <Container>
      <TableContainer>
        <TableWrapper>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
              <Input onChange={e => tableFilterHandler(e)}
                size='small'
                placeholder='Search'
                prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4'/>}
                style={{width: '200px', borderRadius: '6px', border: '1px solid #CDCDCD'}}
              />
              <ReportCount>Websites: <span>{getOttoV2ProjectsList?.count ?? 0}</span></ReportCount>
            </div>
          </div>
          <AntdTableStyled
            loading={loadingProjects}
            loadingRows={10}
            columns={tableColumn}
            dataSource={sitesList}
            scroll={{x: width > 1100 ? 'none' : 'auto', y: 'none'}}
            pagination={false}
          />
          <PaginationStyled
            onChange={async (page, pageSize) => setDefaultParams({...defaultParams, pageSize: pageSize, page: page})}
            showSizeChanger
            defaultPageSize={50}
            total={getOttoV2ProjectsList.count}
            pageSize={defaultParams.pageSize ?? 20}
            current={defaultParams.page ?? 1}
            pageSizeOptions={['5', '10', '20', '50', '100']}
          />
        </TableWrapper>
      </TableContainer>
    </Container>
    <SettingsModal
      isVisible={isInstructionModalVisible}
      setIsVisible={setIsInstructionModalVisible}
      instructionsData={instructionsData}
      setInstructionsData={setInstructionsData}
    />
    <ImportBusinessModal
      onOttoGBPConnect={() => {
        loadOttoV2Project('uuid' in selectedConnectingProject ? selectedConnectingProject?.uuid : null);
        setSelectedConnectingProject(null);
        resetOttoV2Project(null);
        loadOttoV2Projects(true);
      }}
      visible={gbpModalVisible}
      setVisible={setGbpModalVisible}
      ottoProjectId={getOttoV2Project?.uuid}
      ottoConnectedGBPId={getOttoV2Project?.connectedData?.gbpDetails?.locationId ?? (getOttoV2Project?.connectedData?.gbpDetailsV2 && getOttoV2Project?.connectedData?.gbpDetailsV2[0]?.locationId)}
      isOttoV2
    />
    <TopupModal topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />
    <WidgetModal
      isWidgetModalVisible={isWidgetModalVisible}
      setIsWidgetModalVisible={setIsWidgetModalVisible}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      uuid={uuid}
      setUuid={setUuid}
    />
    <DeleteConfirmModal
      isDeleting={isLoading}
      isVisible={isDeletePageModalVisible}
      warningText={`Are you sure you want to delete?`}
      confirmButtonColor={'#ff6262'}
      onCancel={ () => {
        setIsDeletePageModalVisible(false); setSelectedDeleteId(null);
      }}
      onConfirm={ async () => {
        try {
          setIsLoading(true);
          await deleteProject(selectedDeleteId);
          setIsDeletePageModalVisible(false);
          setSelectedDeleteId(null);
          setIsLoading(false);
        } catch {
          setSelectedDeleteId(null);
          setIsDeletePageModalVisible(false);
          setIsLoading(false);
        }
      }}
    />
  </>
  );
});

export const StyledWidgetModal = styled(Modal)`
  .rc-dialog-content {
    .rc-dialog-body {
      border-radius: 10px;
      overflow: hidden !important;
      padding: 0px !important;
      .modal-video {
        height: 230px;
        background-color: #4e0db1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .content-wrapper {
        padding: 25px 25px;
        .body-heading {
          font-size: 24px;
          font-family: 'Inter', sans-serif;
          font-weight: 600;
          margin-bottom: 10px;
          color: #121212;
        }
        .site {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: #121212;
          margin-bottom: 10px;
        }
        .inputs-wrapper {
          margin-bottom: 8px;
          .label-wrapper {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            margin-bottom: 6px;
            .label {
              color: #121212;
            }
            .heading {
              color: #2D6CCA;
              cursor: pointer;
            }

          }
          .domain-input {
            padding: 10px 11px !important;
            color: #A3A4A4 !important;
            border-radius: 8px !important;
          }
          .snippet-wrapper {
            border-radius: 8px;
            background-color: #F2F2F5;
            padding: 14px 12px;

            .snippet-buttons {
              display: flex;
              align-items: center;
              gap: 20px;
              button {
                margin: 0px !important;
              }
              .email-button {
                display: flex;
                align-items: center;
                gap: 6px; color: #2D6CCA;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;
                :hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .website-wrapper {
          margin-bottom: 12px;
          width: 100%;
          display: flex !important;
          align-items: center;
          justify-content: space-between !important;
          .label {
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            color: #121212;
            margin-bottom: 6px;
          }
          .domain-input {
            padding: 10px 11px !important;
            border-radius: 12px !important;
            width: 100%;
            height: 38px;
          }
        }

        .embed-successfully {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          background-color: rgba(42, 193, 85, 0.15);
          border-radius: 6px;
          margin-bottom: 6px;
          gap: 4px;

          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;
          }
        }
        .not-embed {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          background-color: rgba(244, 67, 67, 0.15);
          border-radius: 6px;
          gap: 4px;
          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;
            gap: 2px;

            .check-again-button {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 12px;
              font-family: 'Inter', sans-serif;
              color: #2D6CCA;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .already-embed {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          border: 1px solid #E8E8E8;
          border-radius: 6px;
          margin-bottom: 6px;
          gap: 4px;

          .text {
            display: flex;
            align-items: center;
            color: #121212;
            font-weight: 400;
            font-size: 12px;
            font-family: 'Inter', sans-serif;
            line-height: normal;

            .link {
              color: #2D6CCA;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .banner {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 6px;
            background-color: rgba(45, 108, 202, 0.14);
            margin-bottom: 10px;
            span {
              font-family: 'Inter', sans-serif;
              font-size: 12px;
              font-weight: 400;
            }
          }
        .steps-wrapper {
          position: relative;
          margin-bottom: 8px;

          .steps {
            display: flex;
          }

          .border-line {
            bottom: 3px;
            position: relative;
            width: 100%;
            height: 2px;
            border-bottom: 2px solid #e8e8e8 !important;
          }
          .section {
            flex-grow: 1;
            cursor: pointer;

            &.active-section {
              z-index: 1;
              padding-bottom: 8px;
              border-bottom: 4px solid rgba(45, 108, 202, 1);
            }
            &.restricted {
              cursor: default;
            }
            .step {
              font-size: 12px;
              line-height: 14px;
              font-weight: 400;
              color: #4e5156;

              &.active {
                font-weight: 500;
                color: #121212;
              }
            }
          }
        }
      }
    }
    
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.8;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;

const Container = styled.div`
  background: linear-gradient(rgb(36, 38, 42) 20%, rgb(242, 242, 245) 20%);
  display: flex;
  justify-content: center;
  width: 100%;
`;
const TableWrapper = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 100%;
`;
const TableContainer = styled.div`
  width: 1200px;
  @media screen and (max-width: 1220px) {
    max-width: 1200px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 13px;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #E8E8E8;
  width: 470px;
  margin-bottom: 26px;
`;
const FlexWrapper= styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 12px;
`;
const FlexWrapperHeading= styled.div`
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Heading = styled.div`
  font-weight: 700;
font-size: 28px;
line-height: 32px;
color: #FFFFFF;
`;

const HeadingContainer = styled.div<{isWhitelabel: boolean}>`
  width: 1200px;
  @media screen and (max-width: 1220px) {
    max-width: 1200px;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const HeaderWrapper = styled.div`
    background-color: #24262a;
    cursor: default;
    padding-bottom: 50px;
    padding-top: 40px;
`;
const ReportCount = styled.div`
font-family: 'Inter', sans-serif;
font-weight: 400;
font-size: 14px;
span {
    font-weight: 600;
  }
`;
const AntdTableStyled = styled(AntdTable)`
  .ant-table-body{
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #F9F9FB;
    }

    ::-webkit-scrollbar-thumb {
      background: #4E515680;
      border-radius: 5px;
    }
  }
  .ant-table-content{
    overflow: auto !important;
  }
  .ant-table-thead {
    background: #f7f7f7 !important;
    border-radius: 8px;
    .ant-table-cell-fix-left:first-child  {
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
      font-size: 12px;
      font-weight: 600;
      background: #f7f7f7 !important;
    }
    th:first-child {
      padding: 0px 15px 0px 25px !important;
    }
  }
  .ant-table-thead th:nth-child(2) {
    background: #f7f7f7 !important;
    font-size: 12px;
    font-weight: 600;
  }
  .ant-table-thead th {
    color: #121212 !important;
    border-bottom: unset !important;
    height: 60px !important;
    line-height: 14px;
    padding: 0 !important;
    
    &::before {
      display: none;
    }
}
.ant-table-thead th:last-child {
    background-color: #f7f7f7 !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
    padding-left: 25px !important;
}
  .ant-table-tbody tr:nth-child(odd) td, .ant-table-tbody tr:nth-child(even) td {
    border-bottom: 1px solid #f4f4f4 !important;
    box-shadow: none;
    font-weight: 400;
    white-space: nowrap;
  }
  .ant-table-tbody > tr:first-child td {
    border-top: transparent !important;
  }
  @media screen and (max-width: 1200px) {
    .ant-table-thead th:not(:last-child), .ant-table-tbody tr td:not(:last-child) {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
    .ant-table-thead th {
      white-space: nowrap;
    }
  }
  .ant-table-tbody {
    .ant-table-row {
      height: 38px !important;
      td {
       height: 38px;
       margin: 0 25px !important;
      }
    }
  }
  .ant-pagination {
      align-items: center;
      .ant-pagination-prev, .ant-pagination-next {
        display: flex;
        align-items: center;
        .ant-pagination-item-link {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none !important;
          background: #f7f7f8 !important;
          width: 25px;
          height: 25px;
          border-radius: 4px !important;
        }
      }
      .ant-pagination-options {
        height: 25px !important;
        .ant-select {
          background: #f7f7f8 !important;
          border: 1px solid #e8e8e8 !important;
          border-radius: 4px !important;
          height: 28px;
          justify-content: center;
          margin-bottom: 0;
          padding: 0 5px;
          z-index: 1 !important;
          .ant-select-selector {
            background-color: transparent !important;
            border: 0 !important;
            height: 1.75em !important;
            align-items: center;
          }
        }
      }
      .ant-pagination-item {
        min-width: 25px !important;
        height: 25px !important;
        background:#f7f7f8 !important;
        border: 1px solid #e8e8e8 !important;
        border-radius: 4px !important;
        box-sizing: border-box !important;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;

        &.ant-pagination-item-active {
          background: #2d2f34 !important;
          a {
            color: #fff !important;
          }
        }
        a {
          color: #121212 !important;
          text-decoration: none !important;
        }
      }
    }
`;
const Title = styled.div`
font-family: 'Inter', sans-serif;
font-weight: 700;
font-size: 12px;
color: #121212;
`;
const Img = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  object-fit: contain;
`;
const BoldText = styled.div`
  color: #2D6CCA;
  font-weight: 500;
  font-size: 14px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SaveTimeText = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  text-transform: capitalize;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  cursor: pointer;
`;

const FailedBanner = styled.div`
  padding: 3px 10px;
  border-radius: 35px;
  background-color: #F443431A;
  color: #F44343;

`;
const ButtonStyled = styled(ViewBtton)`
  background-color: #f7f7f8;
  border: 1px solid #E8E8E8 !important;
  padding: 4px 15px;
  color: #4e5156 !important;
  font-size: 14px;
  font-weight: 400 !important;
  outline: none !important;
`;
const StyledIConWrapper = styled.div`
width: 24px;
height: 24px;
border-radius: 6px;
display: flex;
align-items: center;
justify-content: center;
`;
const TextDiv = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;
const WidgetDiv = styled.div<{width: string; backgroundColor: string}>`
  font-weight: 400;
  font-size: 14px;
  color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 6px;
  background-color: ${p => p.backgroundColor};
  height: 32px;
  width: ${p => p.width};
`;

export const SiteAuditWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 14px;

  .existing-site {
    display: flex;
    align-items: center;
    color: #121212;
    font-weight: 400;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    line-height: normal;
    color: #2D6CCA;
    cursor: pointer;
    gap: 8px;
    margin-bottom: 16px;
  }
    .ant-collapse-header{
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    width: fit-content !important;
    }
    .ant-collapse-content-box{
    padding-left: 0 !important;
    }
    .ant-collapse-arrow{
    margin-top: 7px !important;
    }
    `;

export const ErrorDivStyled = styled.div`
  width: max-content;
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: absolute;
  top: 157px;
  z-index: 99;
  left: 14px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  span {
    color: #2D6CCA;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .triangle {
    width: 20px;
    position: absolute;
    height: 8px;
    border-bottom: solid 11px rgb(255 255 255);
    border-left: solid 11px transparent;
    border-right: solid 11px transparent;
    top: -10px;
    left: 20px;
  }
`;

// const DisableButton = styled.div`
//   background: linear-gradient(0deg, rgba(0, 0, 0, 0.17), rgba(0, 0, 0, 0.17)), #2D6CCA;
//   background-color: #2D6CCA;
//   border-color: #2D6CCA;
//   color: #fff;
//   opacity: 50%;
//   text-align: center;
//   display: flex;
//   padding: 0 20px;
//   align-items: center;
//   border-radius: 6px;
//   cursor: not-allowed;
// `;

const RetryBtn = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2D6CCA;
  cursor: pointer;
  text-decoration: underline;
`;

const WhitelistCrawler = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #E8E8E8; 
  cursor: pointer; 
  white-space: nowrap;
`;

