import {Input} from '@/components/common-components/v2';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {H2Section} from './H2Section';
import {Spin, Tooltip} from 'antd';
import {Button} from '@/components/common-components/v2/Button';
import {opaqueColor} from '@/utils/colors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStars} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import React, {useState} from 'react';
import {notification} from '@/utils/notification-v2';
import {LoadingOutlined} from '@ant-design/icons';
import {PAGES_API} from '@/api/content-optimizer';
import {apiError} from '@/utils/api';

interface Props {
  state: any;
  setState: (state: any) => void;
  showError: boolean;
  showMainHeadingMissingError: boolean;
  setShowMainHeadingMissingError: (value: boolean) => void;
  showFocusTopicMissingError: boolean;
  setShowFocusTopicMissingError: (value: boolean) => void;
  contentType: number;
  contentIdea: string;
  setContentIdea: React.Dispatch<React.SetStateAction<string>>;
}

export const Headings = observer(({
  state,
  setState,
  showError,
  showMainHeadingMissingError,
  setShowMainHeadingMissingError,
  showFocusTopicMissingError,
  setShowFocusTopicMissingError,
  contentType,
  contentIdea,
  setContentIdea,
}: Props) => {
  const {contentOptimizer: {currentPage},
    settings: {
      customer: {
        profile: {
          isAllowedAiContentGenerationQuotaLimitReached,
          quotaUtilization,
          isWhitelabel,
        },
        getCustomerQuota,
      },
    },
    plans: {showSidebarPaymentDrawer}} = useStore('');
  const {contentOptimizer: {currentPage: {content, editTitle}}} = useStore('');
  const [loadingAiHeadings, setLoadingAiHeadings] = useState(false);
  const antIconSmall = <LoadingOutlined style={{fontSize: 12, marginRight: '5px', color: '#fff', marginBottom: '4px'}} spin />;
  type H3DTO = {
    h3: string;
  };

  type H2DTO = {
    h2: string;
    h3S: H3DTO[];
  };

  const autoPopulateOutlinesHandler = (h1?: string, h2List?: H2DTO[]) => {
    if (!h2List && !h1) return;

    const items = h2List.map(resp=>({
      value: resp.h2,
      label: 'H2',
      placeholder: 'H2',
      fields: resp.h3S.map(resp=>({
        type: 'h3',
        label: 'H3',
        placeholder: 'Add a related question....',
        value: resp.h3,
      })),
    }));

    setState({
      ...state,
      mainHeading: h1 ?? state.mainHeading,
      items: items,
    });
  };
  // Adds single field into single Focus Topic section
  const addNewField = (type: 'h3', index: number, value?: string) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[index]['fields'];

    fields.push({
      type: type,
      label: type === 'h3' ? 'H3' : '',
      placeholder: type === 'h3' ? 'H3' : '',
      value: value ? value : '',
    });

    items[index]['fields'] = fields;

    const newState = {
      ...curState,
      items: items,
    };

    setState(newState);
  };

  const isEmptyState = (currentInputValue?: string) => {
    let isEmpty = true;
    if (currentInputValue) {
      isEmpty = false;
      return false;
    }
    for (let i = 0; i < state?.items?.length; i++) {
      if (state.items[i].value) {
        isEmpty = false;
        return false;
      } else {
        for (let n = 0; n < state.items[i].fields.length; n++) {
          if (state.items[i].fields[n].value) {
            isEmpty = false;
            return false;
          }
        }
      }
    }

    return isEmpty;
  };

  const getListStyle = () => ({
    width: '100%',
  });

  // Removes single field from single Focus Topic section
  const removeSingleField = (itemIndex: number, fieldIndex: number) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[itemIndex]['fields'];

    fields.splice(fieldIndex, 1);

    items[itemIndex]['fields'] = fields;

    const newState = {
      ...curState,
      fields: fields,
    };

    setState(newState);
    setState({
      ...newState,
      isEmpty: isEmptyState(),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };

  // Removes all fields from single Focus Topic section
  const removeAllFields = (itemIndex: number) => {
    const curState = {...state};
    const items = curState['items'];

    items[itemIndex]['fields'] = [];

    const newState = {
      ...curState,
      fields: [],
    };

    setState(newState);
    setState({
      ...newState,
      isEmpty: isEmptyState(),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };

  // Updates value for single focus topic on field edit
  const updateFocusTopic = (e, index: number) => {
    const curState = {...state};
    const items = curState['items'];
    const topic = items[index];

    topic['value'] = e.target.value;

    items[index] = topic;

    setState({
      ...state,
      items: items,
    });
    setState({
      ...state,
      isEmpty: isEmptyState(e.target.value),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };

  const removeTopicHandler = (index: number) => {
    const curState = {...state};
    const items = [...curState.items];

    items.splice(index, 1);

    setState({
      ...state,
      items: items,
    });
  };


  // Reorders entire Focus Topic sections
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Trigers reorder after entire Focus Topic section drag finishes
  const onSectionDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index,
    );

    setState({
      ...state,
      items: items,
    });
  };

  // Updates state inside single Focus Topic section after reordering fields inside
  const onSingleFieldDragEnd = (index, newState) => {
    const curState = {...state};
    const items = curState['items'];
    items[index]['fields'] = newState;

    setState({
      ...state,
      items: items,
    });
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    return ({
    // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: '0px',
      margin: `0 0 0 0`,

      // styles we need to apply on draggables
      ...draggableStyle,
    });
  };

  // Generates new Focus Topic section
  const addNewTopicField = () => {
    const curState = {...state};
    const items = [...curState.items, {
      value: '',
      label: 'H2',
      placeholder: 'H2',
      fields: [],
    }];

    setState({
      ...state,
      items: items,
    });
  };

  // Updates single field inside single Focus Topic section
  const updateSingleItemValue = (e, itemIndex: number, fieldIndex: number) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[itemIndex]['fields'];

    fields[fieldIndex].value = e.target.value;

    items[itemIndex]['fields'] = fields;

    const newState = {...curState};

    setState(newState);

    setState({
      ...newState,
      isEmpty: isEmptyState(e.target.value),
    });

    if (showMainHeadingMissingError) {
      setShowMainHeadingMissingError(false);
    }
    if (showFocusTopicMissingError) {
      setShowFocusTopicMissingError(false);
    }
  };

  // Clear H3 tags of a single field inside single Focus Topic section
  const clearItemValues = (itemIndex: number) => {
    const curState = {...state};
    const items = curState['items'];
    const fields = items[itemIndex]['fields'];
    const fieldsArray = [];
    fields.map(() => fieldsArray.push(
      {
        type: 'h3',
        label: 'H3',
        placeholder: 'Add a related question....',
        value: '',
      },
    ));
    items[itemIndex]['fields'] = fieldsArray;

    const newState = {...curState};

    setState(newState);

    // setState({
    //   ...newState,
    //   isEmpty: true,
    // });
  };

  // Clear all H2 and H3 tags
  const clearValues = () => {
    const curState = {...state};
    const items = curState['items'];
    const itemsArray = [];
    items.map(item => {
      const emptyItem = {
        value: '',
        placeholder: 'H2',
        fields: [],
      };
      const fields = item['fields'];
      const fieldsArray = [];
      fields.map(() => fieldsArray.push(
        {
          type: 'h3',
          label: 'H3',
          placeholder: 'Add a related question....',
          value: '',
        },
      ));
      emptyItem['fields'] = fieldsArray;
      itemsArray.push(emptyItem);
    });
    curState['items'] = itemsArray;

    const newState = {
      ...curState,
      isEmpty: true,
      mainHeading: '',
    };

    setState(newState);
  };
  const handleAiGenerateHeadings = async () => {
    if (isWhitelabel && isAllowedAiContentGenerationQuotaLimitReached) {
      notification.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
    } else if (!isAllowedAiContentGenerationQuotaLimitReached) {
      setLoadingAiHeadings(true);
      try {
        const resp = await PAGES_API.generateAiOutlines(content.uuid, contentIdea, contentType === 1 ? 'process_full_seo_article_writer' : 'landing_page_copy');
        autoPopulateOutlinesHandler(resp.h1, resp.h2S);
        if (resp?.title) {
          (content.title.startsWith('New Document ') || !content.title) && editTitle(resp?.title);
          contentIdea == '' && setContentIdea(resp?.title);
        }
        getCustomerQuota();
      } catch (e) {
        notification.error(apiError(e) as string, '');
      } finally {
        setLoadingAiHeadings(false);
      }
    } else {
      showSidebarPaymentDrawer(null, {title: 'You need more AI quota to continue', subtitle: '', text: `Current limit: ${quotaUtilization?.ca?.allowedAiContentGeneration?.total} AI content`});
    }
  };

  return <Wrapper >
    {contentType!==3 && (contentIdea || (currentPage?.feTargetKeywords?.length && !currentPage?.isProcessingKws)) ? (
      <CreateHeadingBanner>
        <div className='text'>Build an optimized outline based on SERP data in 1 click.</div>
        {contentType!==3 && (contentIdea || (currentPage?.feTargetKeywords?.length && !currentPage?.isProcessingKws) ?
          <Tooltip title={contentType === 2 ? '' : ''}>
            <AiPopulateHeadingsBtn onClick={() => handleAiGenerateHeadings()} disabled={loadingAiHeadings} disabledStyle={!state?.mainHeading}>
              {loadingAiHeadings ? <Spin indicator={antIconSmall} /> : <FontAwesomeIcon icon={faStars} style={{marginRight: 5, marginBottom: 4}}/>}
              Build with AI
            </AiPopulateHeadingsBtn></Tooltip> :
          <Tooltip title={currentPage?.isProcessingKws ? 'Please wait for all Target Keywords to finish processing or input the content topic.' : 'Please add a keyword above or process Target Keywords on the page to generate the outline (H1 and H2s)'}>
            <div><AiPopulateHeadingsBtn disabled={true} onClick={() => handleAiGenerateHeadings()} disabledStyle={!state?.mainHeading}>
              {loadingAiHeadings ? <Spin indicator={antIconSmall} /> : <FontAwesomeIcon icon={faStars} style={{marginRight: 5, marginBottom: 4}}/>}
              Build with AI
            </AiPopulateHeadingsBtn></div>
          </Tooltip>)}
      </CreateHeadingBanner>) : <></>}
    <DragDropContext onDragEnd={onSectionDragEnd}>
      <TopSectionWrapper>
        {/* <div><FontAwesomeIcon icon={faGripDotsVertical} style={{marginRight: 10}}/></div> */}
        <div style={{width: '100%', marginBottom: 10}}>


          <Input
            type='text'
            variant='light'
            prefix={'H1'}
            // suffix={ <input type='number' value={totalH2Counter} className={styles.numberInput} defaultValue={5} min={1} max={20} pattern='[0-9]' style={{width: '50px', marginBottom: 0}} onChange={e => updateTopicsCount(+e.target?.value)}/>}
            value={state.mainHeading}
            onChange={e => {
              if (showMainHeadingMissingError) {
                setShowMainHeadingMissingError(false);
              }
              if (showFocusTopicMissingError) {
                setShowFocusTopicMissingError(false);
              }
              setState({...state, mainHeading: e.target.value, isEmpty: isEmptyState(e.target.value)});
            }}
            className={'customLargeInput'} />

          {/* {(showError && state.mainHeading === '') && <ErrorMsg>Main Heading is required</ErrorMsg>} */}
        </div>
      </TopSectionWrapper>

      <Droppable droppableId='droppable'>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}
          >
            {state.items.map((item, index) => (
              <Draggable key={index} draggableId={`${item.label}-${index}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <H2Section
                      totalItems={state?.items?.length || 0}
                      totalTerms={item?.fields?.filter(item => item?.type == 'term').length}
                      totalQuestions={item?.fields?.filter(item => item?.type == 'question').length}
                      onSingleFieldDragEnd={onSingleFieldDragEnd}
                      updateItemTopic={updateFocusTopic}
                      currentItemState={item}
                      addField={addNewField}
                      updateItemValue={updateSingleItemValue}
                      removeExistingField={removeSingleField}
                      removeTopic={removeTopicHandler}
                      index={index}
                      showError={showError}
                      clearItemValues={clearItemValues}
                      contentType={contentType}
                      removeAllFields={removeAllFields}
                      calledFromEditor
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

    </DragDropContext>
    <div style={{display: 'flex'}}>
      {state.items.length <= 15 ?
        <AddMoreTopics onClick={() => addNewTopicField()}>+ Add H2</AddMoreTopics> : <></>
      }
      {state.items.length ?
        <ClearTopics onClick={() => clearValues()}>Clear all</ClearTopics> : <></>
      }
    </div>
  </Wrapper>;
});


const Wrapper = styled.div`
  // padding: 18px 30px;

`;

const TopSectionWrapper = styled.div`
  display: flex;
  width: 100%;

  .customLargeInput {
    height: 38px !important;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 12px !important;
    border-radius: 6px !important;
    border: 1px solid #E8E8E8 !important;

    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    }
    .ant-input-suffix {
      padding: 7px 0;
    }
  }
`;

const AddMoreTopics = styled.div`
    color: #2D6CCA;
    font-size: 12px;
    cursor: pointer;
`;

const ClearTopics = styled.div`
    margin: 0 0 0 10px;
    color: #a3a4a4;
    font-size: 12px;
    cursor: pointer;
`;
const AiPopulateHeadingsBtn = styled(Button) <{disabledStyle?: boolean}>`
    border-radius: 4px;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    background-color: ${p => p.disabledStyle ? opaqueColor('#7F4EAD', 100) : opaqueColor('#7F4EAD')} !important;
    font-size: 12px;
    color: #FFFFFF;
    cursor: pointer;
    margin: 0px !important;

    &:hover {
      background-color: ${p => p.disabledStyle ? `${opaqueColor('#7F4EAD', 80)}` : '#7F4EAD'} !important;
    }
    &:focus {
      background-color: ${p => p.disabledStyle ? `${opaqueColor('#7F4EAD', 80)}` : '#7F4EAD'} !important;
    }

    &:disabled {
      background-color: ${opaqueColor('#7F4EAD', 80)};
      border-color: ${opaqueColor('#7F4EAD', 80)};
    }
`;
const CreateHeadingBanner = styled.div`
  margin-bottom: 6px;
  background-color: rgba(127, 78, 173, 0.15);
  border-radius: 8px;
  padding: 8px 9px;
  display: flex;
  align-items: center;
  line-height: normal;
  gap: 32px;
  .text {
    font-size: 12px;
    font-weight: 400;
    color: #57307B;
    font-family: 'Inter', sans-serif;
    line-height: normal;
  }
`;
